import React, { useState } from "react";
import { axiosInstance } from "../../utility/api";
import Swal from "sweetalert2";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    const payload = {
      email: email,
    };

    if (
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      Swal.fire("Oops", "Enter a valid email address.", "error");
      return false;
    }
    if (email !== "") {
      axiosInstance
        .post("/v1/admin/auth/forgot", payload)
        .then((res) => {
          // localStorage.clear();
          //   console.log("qwerty Show : ", res?.data?.result?.accessToken);
          //   setShowLoader(false);

          // if (res?.data?.result?.email) {
          //   setStep1Complete(true);
          //   setLoginStepInfo({
          //     qr: res?.data?.result?.qr,
          //     email: res?.data?.result?.email,
          //   });
          // } else {
          //   setStep1Complete(false);
          // }
          Swal.fire("success", "Check your email for password reset link", "success");
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire("Oops!", err?.response?.data?.message, "error");
        });
    } else {
      Swal.fire("Oops!", "Please enter email.", "error");
    }
  };

  return (
    <div>
      <h3>Forgot your password?</h3>
      <form className="form-group" onSubmit={submitHandler}>
        <label>Email</label>
        <input
          type="email"
          name="email"
          className="form-control"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e?.target?.value)}
        />
        <br />
        <button type="submit" className="btn btn-dark btn-lg btn-block">
          Get Reset Link
        </button>
      </form>
    </div>
  );
}
