import React, { useEffect, useState } from "react";
import "./BulkUpload.css";
import InputField from "./components/InputField";
import TextAreaField from "../../common/TextAreaInput/TextAreaInput";
import ArtistSelectBox from "./components/ArtistSelectBox";
import CreatorBox from "./components/CreatorBox";
import Select from "react-select";
import { axiosInstance } from "../../../utility/api";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import OptionalCreator from "../components/optionalCreator";
import { camelToLowerCase } from "../../../utility/helper";
import Loader from "../../common/Loader/Loader";

function BulkUpload({
  state,
  isEdit = false,
  onCloseDrawer,
  collectionImage,
  createCollectionButtonClicked,
}) {
  const history = useHistory();
  const [collectionFormData, setCollectionFormData] = useState({
    name: "",
    coverimagePath: collectionImage,
    csv: "",
    description: "",
    artist: {
      id: "",
      royaltyCommissionPercentage: "",
      royaltyCommissionCapAmount: "",
      primarySalePercentage: "",
    },
    creator: {
      id: "",
      royaltyCommissionPercentage: "",
      royaltyCommissionCapAmount: "",
      primarySalePercentage: "",
    },
  });
  const [creatorListMain, setCreatorListMain] = useState([]);
  const [creatorList, setCreatorList] = useState([]);
  const [search, setSearch] = useState("");
  const [coCreators, setCoCreators] = useState({});
  const [multipleCoCreator, setMultipleCoCreator] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [token, setToken] = useState("");
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setToken(JSON.parse(localStorage.getItem("token")));
    }
  }, []);

  useEffect(() => {
    if (token != null && token != "" && token != undefined) {
      getCreatorList("");
    }
  }, [token]);

  useEffect(() => {
    if (createCollectionButtonClicked) {
      setSubmitAddCollection();
    }
  }, [createCollectionButtonClicked]);

  useEffect(() => {
    if (collectionImage) {
      setCollectionFormData((prevState) => ({
        ...prevState,
        coverimagePath: collectionImage,
      }));
    }
  }, [collectionImage]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCollectionFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Get Creator List from BE
  const getCreatorList = async (search) => {
    console.log("token,,,", config);
    if (config.headers.Authorization) {
      let query = `?page=1&limit=10&search=${search || ""
        }&isBlock=&fromDate=&toDate=`;
      let newq = `?limit=9999999&page=1&isDropDown=true`;
      // let query = {
      // 	page: 1,
      // 	limit: 10,
      // 	search: '',
      // 	isBlock: '',
      // 	fromDate: '',
      // 	toDate: ''
      // }
      axiosInstance
        .get(`/v1/admin/user${newq}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let arr = [];
          if (res?.data?.result?.users?.length) {
            res.data.result.users.forEach((el) => {
              let obj = {
                ...el,
                value: el?.id,
                label: el?.userName? el?.userName : el.firstName|| el.lastName? el?.firstName + " "+el?.lastName: "userId: "+ el?.id

              };
              arr.push(obj);
            });
          }
          if (!search) setCreatorListMain(arr);
          setCreatorList(arr);
        })
        .catch((err) => {
          console.log("/admin/user Error while getting subcategories:", err);
        });
    }
  };

  // Get Artist Name List
  const getArtistnameList = () => {
    const templist = creatorList.filter((item) => {
      if (
        item?.id != collectionFormData.creator?.id &&item?.isCreatedByAdmin&&item?.publicAddress&&
        item.status === "verified" &&
        !inCocreator(item?.id)
      ) {
        return item;
      }
    });
    return templist;
  };

  // Handle Select box changes

  const handleSelectChange = (e, type, field = null) => {
    if (type === "artistId") {
      setCollectionFormData({
        ...collectionFormData,
        artist: { ...collectionFormData.artist, id: e?.id },
      });
      return;
    } else if (type === "creatorId") {
      setCollectionFormData({
        ...collectionFormData,
        creator: { ...collectionFormData.creator, id: e?.id },
      });
      return;
    } else if (type === "creator" && field) {
      setCollectionFormData({
        ...collectionFormData,
        creator: { ...collectionFormData.creator, [field]: e?.target?.value },
      });
      return;
    }
    setCollectionFormData((prevState) => ({
      ...prevState,
      artist: { ...prevState.artist, [type]: e?.target?.value },
    }));
    // handleInputChange(event);
  };

  console.log(collectionFormData, 'collection')

  const inCocreator = (id) => {
    let flag = 0;
    for (let i = 0; i < multipleCoCreator?.length; i++) {
      if (id == multipleCoCreator[i]?.id) {
        flag = 1;
        return true;
      }
    }
    if (!flag) {
      return false;
    }
  };

  const getCreatorName = () => {
    const templist = creatorList.filter((item) => {
      if (
        item?.id != state.artist?.id &&
        item.status === "verified" &&
        !inCocreator(item?.id) &&
        [5, 6].includes(Number(item?.id))
      ) {
        return item;
      }
    });
    return templist;
  };

  const resetCreatorOrArtist = () => {
    console.log("bulk.hit.reset");
  };

  const handleCreator = (arr) => {
    let finalArr = [];
    arr.forEach((el) => {
      let obj = {
        id: el?.id,
        royaltyCommissionPercentage: el?.royaltyCommissionPercentage,
        royaltyCommissionCapAmount: el?.royaltyCommissionCapAmount,
        primarySalePercentage: el?.primarySalePercentage,
      };
      finalArr.push(obj);
    });
    setCoCreators({
      ...coCreators,
      finalArr,
    });
  };

  const setSubmitAddCollection = () => {
    let hasError = false;

    let ccError = false;
    let rcp = Number(collectionFormData.creator?.royaltyCommissionPercentage);
    let psp = Number(collectionFormData.creator?.primarySalePercentage);
    if (collectionFormData.artist?.royaltyCommissionPercentage)
      rcp =
        rcp + Number(collectionFormData.artist?.royaltyCommissionPercentage);
    if (collectionFormData.artist?.primarySalePercentage)
      psp = psp + Number(collectionFormData.artist?.primarySalePercentage);
    if (multipleCoCreator) {
      multipleCoCreator.forEach((el) => {
        for (const [key, value] of Object.entries(el)) {
          if (!value && !ccError) ccError = key;
        }
        if (Number(el?.royaltyCommissionPercentage))
          rcp = rcp + Number(el?.royaltyCommissionPercentage);
        if (Number(el?.primarySalePercentage))
          psp = psp + Number(el?.primarySalePercentage);
      });
    }

    if (rcp !== 100) {
      Swal.fire(
        "Error!",
        `Creator, Artist and ${multipleCoCreator.length > 0 ? "CoCreator" : ""
        }  royalty commission percentage should be 100% in total`,
        "error"
      );

      hasError = true;
    }
    if (psp !== 100) {
      Swal.fire(
        "Error!",
        `Creator, Artist and  ${multipleCoCreator.length > 0 ? "CoCreator" : ""
        }  primary sale percentage should be 100% in total`,
        "error"
      );

      hasError = true;
    }
    if (ccError) {
      Swal.fire(
        "Error!",
        `CoCreator ${ccError === "id" ? "Name" : camelToLowerCase(ccError)
        } cannot be blank else delete co-creator by clicking on cancel (x) button`,
        "error"
      );

      hasError = true;
    }

    if (!collectionFormData.creator) {
      Swal.fire("Error!", "Creator information is required", "error");
      hasError = true;
    } else {
      const creatorData = collectionFormData.creator;
      const creatorKeys = Object.keys(creatorData);

      for (const key of creatorKeys) {
        if (!creatorData[key]) {
          if (key === "id") {
            Swal.fire("Error!", ` Creator name is required`, "error");
            hasError = true;
          }

          if (key === "primarySalePercentage") {
            Swal.fire(
              "Error!",
              ` Creator Primary Earnings is required`,
              "error"
            );
            hasError = true;
          }

          // if (key === "royaltyCommissionCapAmount") {
          //   Swal.fire("Error!", ` Creator Cap Amount is required`, "error");
          //   hasError = true;
          // }
          if (key === "royaltyCommissionPercentage") {
            Swal.fire(
              "Error!",
              ` Creator Commission Percentage is required`,
              "error"
            );
            hasError = true;
          }
        }
      }
    }
    if (
      collectionFormData?.description == "<p><br></p>" ||
      collectionFormData?.description?.trim() === ""
    ) {
      Swal.fire("Error!", "Please provide a valid description", "error");
      hasError = true;
    }

    if (collectionImage === "") {
      Swal.fire("Error!", "Image is required", "error");
      hasError = true;
    }

    if (collectionFormData?.name?.trim() == "") {
      Swal.fire("Error!", "Please provide a valid name", "error");
      hasError = true;
    }
    if (collectionFormData?.csv === "") {
      Swal.fire("Error!", "CSV is required", "error");
      hasError = true;
    }

    if (!hasError) {
      let form_data = new FormData();
      // Append existing state to the FormData object
      Object.keys(collectionFormData).forEach((key) => {
        if (key === "artist") {
          form_data.append("artist", JSON.stringify(collectionFormData[key]));
        } else if (key === "creator") {
          form_data.append("creator", JSON.stringify(collectionFormData[key]));
        } else form_data.append(key, collectionFormData[key]);
      });

      form_data.append("coCreator", JSON.stringify(multipleCoCreator));
      try {
        setShowLoader(true);
        axiosInstance.post("/v1/upload-csv", form_data, config).then(
          (res) => {
            // Notifiy center state fileupload type.
            Swal.fire("Success!", res?.data?.message, "success").then(
              (result) => {
                setShowLoader(false);

                // This code will be executed after the SweetAlert is dismissed
                if (result.isConfirmed) {
                  // Handle what to do when the user clicks the "OK" button
                  onCloseDrawer();
                } else if (result.isDismissed) {
                  // Handle what to do when the alert is dismissed (clicked outside or pressed Escape)
                }
              }
            );
          },
          function (error) {
            setShowLoader(false);
            let errorMessage = "";
            error?.response?.data?.errors?.errors?.length > 0
              ? (errorMessage = error.response.data.errors.errors.join("\n"))
              : error?.response?.data?.errors
                ? (errorMessage = error?.response?.data?.errors)
                : (errorMessage = error.response.data.message);

            console.log("content type for response is ------", error.response.headers['content-type'])
            if (error.response.headers['content-type'] == 'text/csv; charset=utf-8') {

              errorMessage = 'Your csv file contains Errors. Please check the response file for errors'
              Swal.fire("Error!", errorMessage, "error").then((result) => {
                console.log("clicking Okay",error.response.data)
                const blob = new Blob([error.response.data], { type: 'text/csv' });
                console.log(blob); // Add this line for debugging
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'error_file.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              });

              

            } else {
              Swal.fire("Error!", errorMessage, "error");

            }

            return Promise.reject(error);
          }
        );
      } catch (error) {
        console.log("error");
      }
    }
  };

  // const validateCollectionForm = () => {
  //   if (this.state?.name?.trim() == "") {
  //     Swal.fire("Error!", "Please provide a valid name", "error");
  //     return false;
  //   }
  //   if (
  //     this.state?.isMainFileAudio &&
  //     this.state?.multiFileStore?.supplimentary1?.length == 0
  //   ) {
  //     Swal.fire("Error!", "Thumbnail for the main audio is needed", "error");
  //     return false;
  //   }
  //   if (this?.state?.multiFileStore?.mainFile?.length === 0) {
  //     Swal.fire("Error!", "Please upload main file", "error");
  //     return false;
  //   }

  //   if (!this.state.creator?.id) {
  //     this.errorToast("Creator Name is required");
  //     return false;
  //   }
  //   if (!this.state.creator?.royaltyCommissionPercentage) {
  //     this.errorToast("Creator Commission Percentage is required");
  //     return false;
  //   }
  //   if (!this.state.creator?.primarySalePercentage) {
  //     this.errorToast("Creator Primary Sale Percentage is required");
  //     return false;
  //   }
  //   if (!this.state.creator?.royaltyCommissionCapAmount) {
  //     this.errorToast("Creator Commission Cap Amount is required");
  //     return false;
  //   }
  //   let ccError = false;
  //   let rcp = Number(this.state.creator?.royaltyCommissionPercentage);
  //   let psp = Number(this.state.creator?.primarySalePercentage);
  //   if (this.state.artist?.royaltyCommissionPercentage)
  //     rcp = rcp + Number(this.state.artist?.royaltyCommissionPercentage);
  //   if (this.state.artist?.primarySalePercentage)
  //     psp = psp + Number(this.state.artist?.primarySalePercentage);
  //   this.state.coCreators.forEach((el) => {
  //     for (const [key, value] of Object.entries(el)) {
  //       console.log("value", value, key);
  //       if (!value && !ccError) ccError = key;
  //     }
  //     if (Number(el?.royaltyCommissionPercentage))
  //       rcp = rcp + Number(el?.royaltyCommissionPercentage);
  //     if (Number(el?.primarySalePercentage))
  //       psp = psp + Number(el?.primarySalePercentage);
  //   });
  //   console.log("ccErrorasdasdasdasdasdasdasds", rcp, psp, ccError);
  //   if (rcp !== 100) {
  //     this.errorToast(
  //       "Creator, Artist and CoCreator royalty commission percentage should be 100% in total"
  //     );
  //     return false;
  //   }
  //   if (psp !== 100) {
  //     this.errorToast(
  //       "Creator, Artist and CoCreator primary sale percentage should be 100% in total"
  //     );
  //     return false;
  //   }
  //   if (ccError) {
  //     this.errorToast(
  //       `CoCreator ${
  //         ccError === "id" ? "Name" : camelToLowerCase(ccError)
  //       } cannot be blank else delete co-creator by clicking on cancel (x) button`
  //     );
  //     return false;
  //   }
  // }

  return (
    <>
      {showLoader && <Loader loaderInfo={"Please wait..."} />}
      <InputField
        label="Collection Name"
        name="name"
        value={collectionFormData?.name}
        handleChange={handleInputChange}
        required={true}
        isEdit={false}
        maxLength={90}
      />
      <InputField
        label="Collection Slug"
        name="slug"
        value={collectionFormData?.slug}
        handleChange={handleInputChange}
        required={true}
        isEdit={false}
        maxLength={90}
      />
      <InputField
        label="Collection Description"
        name="description"
        value={collectionFormData?.description}
        handleChange={handleInputChange}
        required={true}
        isEdit={false}
        maxLength={1500}
      />
      {/* <TextAreaField
        value={collectionFormData?.description}
        onChange={handleInputChange}
        maxLength={250}
      /> */}
      <div className="form-group mb-6">
        <label htmlFor="description">CSV file</label>
        <input
          type="file"
          id="csvFile"
          accept=".csv"
          onChange={(e) =>
            setCollectionFormData((prevState) => ({
              ...prevState,
              csv: e?.target.files[0],
            }))
          }
        />
      </div>

      <CreatorBox
        handleChange={handleInputChange}
        collectionFormData={collectionFormData}
        isEdit={false}
        state={collectionFormData}
        handleSelectChange={handleSelectChange}
        multipleCoCreator = {multipleCoCreator}
      />
      <ArtistSelectBox
        state={collectionFormData}
        collectionFormData={collectionFormData}
        value={collectionFormData?.artist.artistId}
        handleChange={handleInputChange}
        isEdit={false}
        getArtistnameList={getArtistnameList}
        creatorListMain={creatorListMain}
        handleSelectChange={handleSelectChange}
      />

      <OptionalCreator
        handleCreator={handleCreator}
        creatorList={creatorList}
        setSearch={setSearch}
        state={collectionFormData}
        isEdit={isEdit}
        setMultipleCoCreator={setMultipleCoCreator}
      />
    </>
  );
}

export default BulkUpload;
