/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import { axiosInstance } from "../../utility/api";
import Auth from "../../utility/auth";
import { useDispatch, useSelector } from "react-redux";
import { SAVE_TOKEN } from "../../redux/actionTypes";
import TwoFactorAuth from "./TwoFactorScreen";
import ForgotPassword from "./ForgotPassword";
import Logo from "../../assets/images/logo/logo_asly.png";
import EyeIcon from "../../assets/images/icons/eye.svg";
import EyeOffIcon from "../../assets/images/icons/eye-off.svg";
import "./Login.css";

export default function Login() {
	let history = useHistory();
	const [login, setLogin] = useState({
		email: "",
		password: "",
	});
	const [loginStepInfo, setLoginStepInfo] = useState({
		qr: null,
		email: null,
	});
	// const [showLoader, setShowLoader] = useState(false);
	const [step1Complete, setStep1Complete] = useState(false);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const dispatch = useDispatch();
	const userToken = useSelector((state) => state.adminReducer.userToken);

	useEffect(() => {
		if (userToken) {
			Auth.login(() => {
				history.push("/welcome/:layout");
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userToken]);

	useEffect(() => {
		if (localStorage.getItem("token") == undefined) {
			localStorage.clear();
		}
	}, []);

	const handleChange = (e) => {
		if (e.target.name !== "username") {
			setLogin({
				...login,
				[e.target.name]: e.target.value,
			});
		} else if (e.target.name === "username") {
			setLogin({
				...login,
				[e.target.name]: e.target.value.toLowerCase(),
			});
		}
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (
			login.email &&
			login.password &&
			login.email !== "" &&
			login.password !== ""
		) {
			// setShowLoader(true);
			axiosInstance
				.post("/v1/admin/auth", login)
				.then((res) => {
					localStorage.clear();
					console.log("qwerty Show : ", res?.data?.result?.accessToken);
					// setShowLoader(false);
					if (res?.data?.result?.accessToken) {
						Swal.fire("Welcome to lii!", res.data.message, "success");
						localStorage.setItem(
							"token",
							JSON.stringify(res?.data?.result?.accessToken)
						);
						dispatch({
							type: SAVE_TOKEN,
							payload: JSON.stringify(res?.data?.result?.accessToken),
						});
					} else if (res?.data?.result?.email) {
						setStep1Complete(true);
						setLoginStepInfo({
							qr: res?.data?.result?.qr,
							email: res?.data?.result?.email,
						});
					} else {
						setStep1Complete(false);
						Swal.fire("Error", "Error while login.", "error");
					}
				})
				.catch((err) =>
					Swal.fire("Oops", err?.response?.data?.message, "warning")
				);
		} else if (!login.email && !login.password) {
			Swal.fire(
				"Oops!",
				"Please Enter Your Email/Phone number & Password",
				"error"
			);
		} else if (login.email && !login.password) {
			Swal.fire("Oops!", "Please Enter Your Password", "error");
		} else if (!login.email && login.password) {
			Swal.fire("Oops!", "Please Enter Your Email/Phone number", "error");
		}
	};

	return (
		<>
			{!step1Complete ? (
				!forgotPassword ? (
					<div className="loginWrapper">
						<form onSubmit={submitHandler}>
							<div className="logoWrapperLogin" style={{opacity: 0}}>
								<img src={Logo} alt="logo" className="logoImageLogin" />
							</div>

							<h3>Login</h3>
							<div className="form-group">
								<label>Email</label>
								<input
									type="email"
									name="email"
									className="form-control"
									placeholder="Enter email"
									value={login.username}
									onChange={handleChange}
								/>
							</div>

							<div className="form-group">
								<label>Password</label>
								<div className="displayFlex positionRelative">
									<input
										type="password"
										name="password"
										className="form-control"
										placeholder="Enter password"
										onChange={handleChange}
										id="password"
									/>
									{showPassword ? (
										<img
											src={EyeIcon}
											alt="eye"
											className="passwordEyeIcon"
											onClick={() => {
												setShowPassword(!showPassword);
												document.getElementById("password").attributes[
													"type"
												].value = "password";
											}}
										/>
									) : (
										<img
											src={EyeOffIcon}
											alt="eye"
											className="passwordEyeIcon"
											onClick={() => {
												setShowPassword(!showPassword);
												document.getElementById("password").attributes[
													"type"
												].value = "text";
											}}
										/>
									)}
								</div>
							</div>

							<div className="form-group">
								<div className="custom-control custom-checkbox">
									<input
										type="checkbox"
										className="custom-control-input"
										id="customCheck1"
									/>
								</div>
							</div>

							<button type="submit" className="btn btn-dark btn-lg btn-block">
								Sign in
							</button>
							<p className="forgot-password text-right cursorPointer">
								Forgot{" "}
								<span onClick={() => setForgotPassword(true)}>password?</span>
							</p>
						</form>
					</div>
				) : (
					<ForgotPassword />
				)
			) : (
				<TwoFactorAuth loginStepInfo={loginStepInfo} />
			)}
		</>
	);
}
