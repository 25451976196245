/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import "./TransactionHistoryTable.css";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import Loader from "../../components/common/Loader/Loader";
import DataRangePopOver from "../common/DateRange/DateRangePopOver";
import { formatDateTime } from "../common/date";
import { axiosInstance, baseURL } from "../../utility/api";
import { useDebounce } from "../../hooks/useDebounce";
import { handleAPIError } from "../../utility/helper";

const useRowStyles = makeStyles({
	root: {
		marginTop: "0px",
		border: "none",
		width: "100%",
	},
	header: {
		backgroundColor: "#F5F5FC",
	},
	rowDatas: {
		"& td,th": {
			border: "none",
			height: "90px",
		},
	},
	border__right: {
		borderRight: "2px solid white",
		height: "70%",
	},
	cursorDefault: {
		cursor: "default",
	},
	height24: {
		height: "24px",
	},
});

//Each row of table
function Row(props) {
	const { data, tableColumnWidth, fiatValue } = props;
	const [open, setOpen] = React.useState(false);
	//const [openPreview, setOpenPreview] = useState({ open: false, id: data?.id });
	// const token = JSON.parse(localStorage.getItem("token"));
	const classes = useRowStyles();
	const polygon =
		process.env.REACT_APP_NODE_ENV === "development"
			? "https://mumbai.polygonscan.com/tx/"
			: "https://mumbai.polygonscan.com/tx/";

	const getCreatedAt = (date) => {
		let d = formatDateTime(date);
		// let d =  moment.utc(date).local().format('DD/MM/YYYY hh:mm:ss A')
		let index = d.indexOf(" ");
		return (
			<>
				{d.substring(0, index)}{" "}
				<span style={{ whiteSpace: "nowrap" }}>{d.substring(index + 1)}</span>
			</>
		);
	};

	return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.id ? data?.id : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">
            {data?.createdAt ? getCreatedAt(data?.createdAt) : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div
            className={`columnStyles messageBoxStyle ${
              data?.status === "success" ? "order-sucess" : "order-not-complete"
            }`}
          >
            {data?.status}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles-sm columnStyles">{data?.from}</div>
        </TableCell>

        {/* <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <OrderDetailsModal orderId={data?.id} />
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles-sm columnStyles">
            {/* <a
              href={`https://polygonscan.com/tx/${data?.orderTx}`}
              target="_blank"
              rel="noreferrer"
            
            > */}
            {data?.to}
            {/* </a> */}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          {data?.orderTx ? (
            <div className="columnStyles-sm columnStyles">
              <a
                href={`${polygon}${data?.orderTx}`}
                target="_blank"
                rel="noopener noreferrer"
                className="viewOnPolygonScan"
              >
                View
              </a>
            </div>
          ) : (
            <div className="columnStyles-sm columnStyles">-</div>
          )}
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <div
            className="columnStyles captitalize "
            style={{ wordBreak: "normal" }}
          >
            {data?.paymentType ? data?.paymentType : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className="columnStyles">
            {/* {data?.totalPrice} */}
            {`${data?.totalPrice} $  `}{" "}
            <span style={{ whiteSpace: "nowrap" }}>
			({data?.totalPrice ? data?.totalPrice  : '0'} USDC)

              {/* ({(data?.totalPrice * fiatValue).toFixed(2)} MATIC) */}
            </span>
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
          <div className="columnStyles" style={{ wordBreak: "break-word" }}>
            {/* {data?.asset && data?.asset?.name} {data?.asset?.purchasedBy || data?.status=="success" ? `#${data?.asset?.currentEdition}/${data?.asset?.totalEdition}`:""} */}
            {data?.asset && data?.asset?.name}{" "}
            {/* {data?.type=="resell"
              ? `#${data?.asset?.currentEdition}/${data?.asset?.totalEdition}`
              : data?.asset?.purchasedBy && data?.status == "success"
              ? `#${data?.asset?.currentEdition}/${data?.asset?.totalEdition}`
              : ""} */}
			  { data?.status == "success"
              && `#${data?.asset?.currentEdition? data.asset.currentEdition : 1}/${data?.asset?.totalEdition}`
              }
          </div>
        </TableCell>
      </TableRow>
      {/* {openPreview?.open ? (
        <OrderDetailsModal orderId={openPreview?.id} />
      ) : null} */}
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
	const [sortingField, setSortingField] = useState("");
	const [sortingOrder, setSortingOrder] = useState("asc");

	const onSortingChange = (field) => {
		const order =
			field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
		setSortingField(field);
		setSortingOrder(order);
		onSorting(field, order);
	};

	const classes = useRowStyles();
	return (
		<TableHead className={classes.header}>
			<TableRow>
				{columns?.map(({ name, field, sortable, image }) => (
					<TableCell
						align={columns.align}
						key={name}
						className={classes.cursorDefault}
					>
						<div className="dividerFlex">
							<div
								style={{
									display: "flex",
									justifyContent: "left",
									alignItems: "center",
									fontWeight: "600",
									fontSize: "12px",
									cursor: sortable ? "pointer" : "default",
								}}
								onClick={() => (sortable ? onSortingChange(field) : null)}
							>
								{name}
								{/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
							</div>
						</div>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default function TransactionHistoryTable({
	data,
	showLoader,
	setShowLoader,
}) {
	const classes = useRowStyles();
	const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
	let today = new Date();
	let year = today.getFullYear();
	let month = today.getMonth();
	let day = today.getDate();
	let date1MonthBefore = new Date(year, month - 1, day);
	const [transactionHistory, setTransactionHistory] = useState(null);
	const [pageNo, setPageNo] = useState(1);
	const [totalCount, setTotalCount] = useState(1);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectionRange, setSelectionRange] = useState({
		startDate: date1MonthBefore,
		endDate: new Date(),
		key: "selection",
	});
	const [searchText, setSearchText] = useState("");
	const [fiatValue, setFiatValue] = useState(1);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [statusFilterSelected, setStatusFilterSelected] = useState("");
	const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(false);
	const token = JSON.parse(localStorage.getItem("token"));
	const header = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const tableColumnWidth = [5, 12, 8, 15, 15, 10, 14, 20];
	const columns = [
		{
			name: "Order ID",
			field: "Id",
			// image: Divider,
			numeric: false,
			sortable: false,
			align: "left",
		},
		{
			name: "Date",
			field: "date",
			// image: Divider,
			numeric: false,
			sortable: false,
			align: "left",
		},
		{
			name: "Status",
			field: "status",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "From Wallet Address",
			field: "from",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "To Wallet Address",
			field: "to",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "View on Polygonscan",
			field: "view",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Payment Method",
			field: "PaymentMethod",
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Transaction Amount($)",
			field: "transactionAmount",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Asset Name",
			field: "asset",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
	];

	useEffect(() => {
		getTransactionHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statusFilterSelected, pageNo, selectionRange, isDateFilterEnabled]);

	useDebounce(
		() => {
			if (searchText != null && searchText != "" && searchText != undefined) {
				getTransactionHistory();
			}
		},
		[searchText],
		1000
	);

	useEffect(() => {
		const getCryptoPrice = () => {
			axiosInstance
				.get(`/v1/admin/exchange/rate`, header)
				.then((res) => {
					setFiatValue(res?.data?.result?.exchangeRate);
				})
				.catch((error) => {
					console.log("Error while receiving MATIC value :", error);
				});
		};
		getCryptoPrice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSelect = (ranges) => {
		setSelectionRange(ranges?.selection);
		setPageNo(1);
	};

	const handleChangePage = (event, value) => {
		setPageNo(value);
		window.scrollTo(0, 0);
	};

	const downloadReport = () => {
		// const url = `${baseURL}v1/user/csv/download`;

		// Caluculating startDate and endDate to pass to backend
		let statusFilter = statusFilterSelected == -1 ? "" : statusFilterSelected;

		let startDate = new Date(
			selectionRange?.startDate?.getTime() -
				selectionRange?.startDate?.getTimezoneOffset() * 60000
		).toISOString();

		let endDate = new Date(
			selectionRange?.endDate?.getTime() -
				selectionRange?.endDate?.getTimezoneOffset() * 60000
		).toISOString();
		//To set endDate time last minute of the day
		// endDate = moment(endDate).add(1, "days");
		endDate = moment(endDate);

		// Data Range library issue while selecting yesterday, Below bypass time correction for dates selected from sidemenu (eg: select yesterday from side bar of dataRange interface)
		if (moment(selectionRange?.endDate).format("ss") != 59) {
			endDate = moment(endDate).add(1, "days");
		}

		endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
		endDate = endDate.toISOString();

		const url = `${baseURL}v1/admin/transactions/download?page=${
			pageNo ? pageNo : 1
		}&limit=200&fromDate=${isDateFilterEnabled ? startDate : ""}&toDate=${
			isDateFilterEnabled ? endDate : ""
		}&search=${searchText ? encodeURIComponent(searchText) : ""}&status=${
			statusFilter ? statusFilter : ""
		}`;
		window.open(url, "_blank");
	};

	// const handleChangePage = (event, nextPageNo) => {
	//   setPageNo(nextPageNo);
	//   window.scrollTo(0, 0);
	// };

	const getTransactionHistory = async () => {
		let startDate = new Date(
			selectionRange?.startDate?.getTime() -
				selectionRange?.startDate?.getTimezoneOffset() * 60000
		).toISOString();

		let endDate = new Date(
			selectionRange?.endDate?.getTime() -
				selectionRange?.endDate?.getTimezoneOffset() * 60000
		).toISOString();
		//To set endDate time last minute of the day
		// endDate = moment(endDate).add(1, "days");
		endDate = moment(endDate);

		// Data Range library issue while selecting yesterday, Below bypass time correction for dates selected from sidemenu (eg: select yesterday from side bar of dataRange interface)
		if (moment(selectionRange?.endDate).format("ss") != 59) {
			endDate = moment(endDate).add(1, "days");
		}

		endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
		endDate = endDate.toISOString();
		let statusFilter = statusFilterSelected == -1 ? "" : statusFilterSelected;
		await axiosInstance
			.get(
				`/v1/admin/transactions?page=${pageNo}&limit=10&search=${encodeURIComponent(
					searchText
				)}&status=${statusFilter}&fromDate=${
					isDateFilterEnabled ? startDate : ""
				}&toDate=${isDateFilterEnabled ? endDate : ""}`,
				header
			)
			.then((result) => {
				setTransactionHistory(result?.data?.result?.transactions?.rows);
				setTotalCount(result?.data?.result?.totalPages);
			})
			.catch((err) => {
				let respErr = handleAPIError(err)
				Swal.fire("Oops!", respErr, "error");
			});
	};

	return (
		<div className="table_div tableWidth100">
			{showLoader == true ? <Loader /> : null}
			<div className="searchBoxProduct">
				{/* <div className="CommissionBlueBox">
          <div className="boxLabel">Total Users</div>
          <div className="boxContent">1700</div>
        </div> */}
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					endIcon={<CloudDownloadIcon>Report</CloudDownloadIcon>}
					onClick={() => downloadReport()}
				>
					Report
				</Button>
				<Button
					variant="outlined"
					onClick={() => {
						setSelectionRange({
							startDate: date1MonthBefore,
							endDate: new Date(),
							key: "selection",
						});
						setStatusFilterSelected(-1);
						setSearchText("");
						setIsDateFilterEnabled(false);
						setPageNo(1);
					}}
				>
					Reset Filter
				</Button>
				<div
					className="height55px displayFlex"
					onClick={() => setIsDateFilterEnabled(true)}
				>
					<DataRangePopOver
						selectionRange={selectionRange}
						setSelectionRange={setSelectionRange}
						handleSelect={handleSelect}
					/>
				</div>
				<FormControl variant="outlined" className={classes.height24}>
					<InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
					<Select
						native
						value={statusFilterSelected}
						onChange={(e) => {
							setStatusFilterSelected(e.target.value);
							setPageNo(1);
						}}
						label="Filter"
						inputProps={{
							name: "Filter",
							id: "outlined-age-native-simple",
						}}
						IconComponent={FilterListIcon}
						displayEmpty={true}
					>
						<svg
							className="w-6 h-6"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
								clipRule="evenodd"
							/>
						</svg>
						<optgroup label="Status">
							<option value={-1}>All</option>
							<option value={"success"}>Success</option>
							<option value={"pending"}>Pending</option>
							<option value={"queue"}>Queue</option>
							<option value={"failed"}>Failed</option>
						</optgroup>
					</Select>
				</FormControl>
				<Autocomplete
					id="search-box-product"
					options={searchSuggestions}
					disableClearable={true}
					clearOnBlur={false}
					inputValue={searchText}
					getOptionLabel={(option) => {
						return option.name || "";
					}}
					style={{ width: 300 }}
					renderInput={(params) => (
						<TextField
							{...params}
							// value={searchText}

							onChange={(e) => {
								setSearchText(e.target.value);
								setPageNo(1);
							}}
							// onKeyDown={(e) => handleSearchBoxKeyDown(e)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							label="Search Order"
							variant="outlined"
						/>
					)}
				/>
			</div>
			{transactionHistory?.length !== 0 ? (
				<>
					<TableContainer component={Paper}>
						<Table aria-label="collapsible table" className={classes.root}>
							<RenderHeader
								columns={columns}
								onSorting={(field, order) => setSorting({ field, order })}
							/>
							<TableBody style={{ width: "100%" }}>
								{transactionHistory?.map((row, index) => (
									<Row
										aria-label="expand row"
										size="small"
										key={row?.id}
										row={row}
										setSelectedRow={(value) => setSelectedRow(value)}
										selectedRow={selectedRow}
										data={transactionHistory[index]}
										tableColumnWidth={tableColumnWidth}
										fiatValue={fiatValue}
										// setOpenEdit={setOpenEdit}
										// setShowLoader={setShowLoader}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Pagination
						count={totalCount}
						page={pageNo}
						onChange={handleChangePage}
					/>
					{/* <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          /> */}
				</>
			) : (
				<div className="noResultFound flexColumnCenter">
					<NoRecordsFound message="No records found." />
				</div>
			)}
		</div>
	);
}
