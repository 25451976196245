/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Pagination from "@material-ui/lab/Pagination";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import "./OrderTable.css";
import Loader from "../../common/Loader/Loader";
import OrderDetailsModal from "../OrderDetails/OrderDetailsModal";
import DataRangePopOver from "./dateRanagePopOver";
import NoRecordsFound from "../../common/Error/NoRecordsFound";
import { formatDateTime } from "../../common/date";
import { axiosInstance, baseURL } from "../../../utility/api";
import { useDebounce } from "../../../hooks/useDebounce";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
  height24: {
    height: "24px",
  },
});

//Each row of table
function Row(props) {
  const { data, tableColumnWidth, fiatValue } = props;
  const [open, setOpen] = React.useState(false);
  const [openPreview, setOpenPreview] = useState({ open: false, id: data?.id });
  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();
  const polygon =
    process.env.REACT_APP_NODE_ENV === "development"
      ? "https://mumbai.polygonscan.com/tx/"
      : "https://mumbai.polygonscan.com/tx/";

  const getCreatedAt = (date) => {
    let d = formatDateTime(date);
    // let d =  moment.utc(date).local().format('DD/MM/YYYY hh:mm:ss A')
    let index = d.indexOf(" ");
    return (
      <>
        {d.substring(0, index)}{" "}
        <span style={{ whiteSpace: "nowrap" }}>{d.substring(index + 1)}</span>
      </>
    );
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "pointer" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.id}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">
            {data?.createdAt ? getCreatedAt(data?.createdAt) : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div
            class={`columnStyles ${
              data?.status === "success" ? "order-sucess" : "order-not-complete"
            }`}
          >
            {data?.status}
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <OrderDetailsModal orderId={data?.id} />
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">{data?.from && data?.from}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles" style={{ wordBreak: "break-word" }}>
          {/* {data?.type=="resell"
              ? `#${data?.asset?.currentEdition}/${data?.asset?.totalEdition}`
              : data?.asset?.purchasedBy && data?.status == "success"
              ? `#${data?.asset?.currentEdition}/${data?.asset?.totalEdition}`
              : ""} */}
             {data?.asset?.name && data?.asset?.name} { data?.status == "success"
              && `#${data?.asset?.currentEdition?data?.asset.currentEdition: 1}/${data?.asset?.totalEdition}`
              }
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <div className="columnStyles">
            {data?.asset?.id && data?.asset?.id}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          {data?.orderTx ? (
            <div className="columnStyles-sm columnStyles">
              <a
                href={`${polygon}${data?.orderTx}`}
                target="_blank"
                rel="noopener noreferrer"
                className="viewOnPolygonScan"
              >
                View
              </a>
            </div>
          ) : (
            <div className="columnStyles-sm columnStyles">-</div>
          )}
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className="columnStyles">
            <button className="viewPolyScanButton" type="button">
              View on polyscan
            </button>
          </div>
        </TableCell> */}
        <TableCell
          style={{
            width: `${tableColumnWidth[7]}%`,
            textTransform: "capitalize",
          }}
        >
          <div className="columnStyles" style={{ wordBreak: "normal" }}>
            {data?.paymentType ? data?.paymentType : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[8]}%` }}>
          <div className="columnStyles">
            {`${data?.unitPrice} $  `}{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              ({data?.unitPrice ? data?.unitPrice  : '0'} USDC)
              {/* ({(data?.unitPrice * fiatValue).toFixed(2)} MATIC) */}
            </span>
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[9]}%` }}>
          <OrderDetailsModal orderId={openPreview?.id} />
          {/* <Tooltip title="Download">
            <img
              src={Download}
              className="marginLeft20 cursorPointer"
              alt="download"
            />
          </Tooltip> */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: "12px solid rgb(245, 245, 252)",
          }}
          colSpan={10}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <ExpandedRow
                rowData={data}
                setShowLoader={(e) => setShowLoader(e)}
                showLoader={showLoader}
              /> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {/* {openPreview?.open ? (
        <OrderDetailsModal orderId={openPreview?.id} />
      ) : null} */}
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function OrderTable({ data, showLoader, setShowLoader }) {
  const classes = useRowStyles();
  const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let date1MonthBefore = new Date(year, month - 1, day);
  const [orderList, setOrderList] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: date1MonthBefore,
    endDate: new Date(),
    key: "selection",
  });
  const [fiatValue, setFiatValue] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [statusFilterSelected, setStatusFilterSelected] = useState("");
  const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tableColumnWidth = [5, 15, 4, 15, 12, 6, 8, 11, 10, 3];
  const columns = [
    {
      name: "Order ID",
      field: "order",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Date",
      field: "date",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Status",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "View",
    //   field: "view",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Wallet Address",
      field: "address",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Asset",
      field: "asset",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Asset ID",
      field: "assetId",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "View on Polygonscan",
      field: "view",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Payment Method",
      field: "paymentMethod",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Net Sales($)",
      field: "newsale",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Action",
      field: "action",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  useEffect(() => {
    getOrderList();
    const getCryptoPrice = () => {
      axiosInstance
        .get(`/v1/admin/exchange/rate`, header)
        .then((res) => {
          setFiatValue(res?.data?.result?.exchangeRate);
        })
        .catch((error) => {
          console.log("Error while receiving MATIC value :", error);
        });
    };
    getCryptoPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionRange, statusFilterSelected, pageNo, isDateFilterEnabled]);

  useDebounce(
    () => {
      if (searchText != null && searchText != "" && searchText != undefined) {
        getOrderList();
      }
    },
    [searchText],
    1000
  );

  const handleSelect = (ranges) => {
    setSelectionRange(ranges?.selection);
    setPageNo(1);
  };

  const handleChangePage = (event, value) => {
    setPageNo(value);
    window.scrollTo(0, 0);
  };

  const downloadReport = () => {
    // Caluculating startDate and endDate to pass to backend
    let statusFilter = statusFilterSelected == -1 ? "" : statusFilterSelected;

    let startDate = new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString();

    let endDate = new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString();
    //To set endDate time last minute of the day
    // endDate = moment(endDate).add(1, "days");
    endDate = moment(endDate);

    // Data Range library issue while selecting yesterday, Below bypass time correction for dates selected from sidemenu (eg: select yesterday from side bar of dataRange interface)
    if (moment(selectionRange?.endDate).format("ss") != 59) {
      endDate = moment(endDate).add(1, "days");
    }

    endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
    endDate = endDate.toISOString();

    const url = `${baseURL}v1/admin/order/download?page=${pageNo}&fromDate=${
      isDateFilterEnabled ? startDate : ""
    }&toDate=${isDateFilterEnabled ? endDate : ""}&search=${encodeURIComponent(
      searchText
    )}&status=${statusFilter}`;
    window.open(url, "_blank");
  };

  const getOrderList = async () => {
    let startDate = new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString();
    let endDate = new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString();

    // Data Range library issue while selecting yesterday, Below bypass time correction for dates selected from sidemenu (eg: select yesterday from side bar of dataRange interface)
    if (moment(selectionRange?.endDate).format("ss") != 59) {
      endDate = moment(endDate).add(1, "days");
    }
    //To set endDate time last minute of the day
    // endDate = moment(endDate).add(1, "days");
    endDate = moment(endDate);
    endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
    endDate = endDate.toISOString();

    let statusFilter = statusFilterSelected == -1 ? "" : statusFilterSelected;
    await axiosInstance
      .get(
        `/v1/admin/order?page=${pageNo}&fromDate=${
          isDateFilterEnabled ? startDate : ""
        }&toDate=${
          isDateFilterEnabled ? endDate : ""
        }&search=${encodeURIComponent(searchText)}&status=${statusFilter}`,
        header
      )
      .then((result) => {
        setOrderList(result?.data?.result?.orders);
        setTotalCount(result?.data?.result?.totalPages);
      })
      .catch((err) => {
        if (
          err?.response &&
          err?.response?.data?.errors &&
          err?.response?.data?.errors?.length > 1
        ) {
          Swal.fire({
            title: "Oops!",
            text: err?.response?.data?.errors.map((err) => err.msg),
            icon: "error",
          });
        } else {
          Swal.fire("Oops!", err?.response?.data?.message, "error");
        }
      });
  };

  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <div className="searchBoxProduct">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<CloudDownloadIcon>Report</CloudDownloadIcon>}
          onClick={() => downloadReport()}
        >
          Report
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setSelectionRange({
              startDate: date1MonthBefore,
              endDate: new Date(),
              key: "selection",
            });
            setStatusFilterSelected(-1);
            setSearchText("");
            setIsDateFilterEnabled(false);
            setPageNo(1);
          }}
        >
          Reset Filter
        </Button>
        <div
          className="height55px displayFlex"
          onClick={() => setIsDateFilterEnabled(true)}
        >
          <DataRangePopOver
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            handleSelect={handleSelect}
          />
        </div>
        {/* <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} /> */}
        <FormControl variant="outlined" className={classes.height24}>
          <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
          <Select
            native
            value={statusFilterSelected}
            onChange={(e) => {
              setStatusFilterSelected(e.target.value);
              setPageNo(1);
            }}
            label="Filter"
            inputProps={{
              name: "Filter",
              id: "outlined-age-native-simple",
            }}
            IconComponent={FilterListIcon}
            displayEmpty={true}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clipRule="evenodd"
              />
            </svg>
            <optgroup label="Status">
              <option value={-1}>All</option>
              <option value={"success"}>Success</option>
              <option value={"pending"}>Pending</option>
              <option value={"failed"}>Failed</option>
            </optgroup>
            {/* <optgroup label="Category"> */}
            {/* {categoryList?.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))} */}
            {/* </optgroup> */}
          </Select>
        </FormControl>
        <Autocomplete
          id="search-box-product"
          options={searchSuggestions}
          disableClearable={true}
          clearOnBlur={false}
          getOptionLabel={(option) => {
            return option.name || "";
          }}
          style={{ width: 300 }}
          inputValue={searchText}
          renderInput={(params) => (
            <TextField
              {...params}
              // value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value?.trimStart());
                setPageNo(1);
              }}
              // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Search Order"
              variant="outlined"
            />
          )}
        />
      </div>
      {orderList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {orderList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={orderList[index]}
                    fiatValue={fiatValue}
                    tableColumnWidth={tableColumnWidth}
                    // setOpenEdit={setOpenEdit}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalCount}
            page={pageNo}
            onChange={handleChangePage}
          />
          {/* <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          /> */}
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
