import axios from "axios";
import Swal from "sweetalert2";

  export const baseURL = process.env.REACT_APP_BASE_URL;
// export const baseURL = "https://api-lii-uat.devtomaster.com/admin/";
// export const baseURL = "https://api-asly-dev1.devtomaster.com/admin/";
export const customerWebUrl = process.env.REACT_APP_CUSTOMER_WEB_URL ;

export const axiosInstance = axios.create({
  baseURL,
});


axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response.status === 401) {
      Swal.fire("Alert", "Session end, please login again", "error").then(
        () => {
          window.location.href = `${process.env.PUBLIC_URL}/sign-in`;
          localStorage.clear();
        }
      );
      return new Promise(() => {});
    }

    //for temp

    // if (err.response.status === 400) {
    //   Swal.fire("Alert", err.response.data.message, "error");
    //   return new Promise(() => {});
    // }

    if (err.response.status === 500) {
      Swal.fire("Alert", "Internal Server Error", "error");
      return new Promise(() => {});
    }

    //im returing a promise to reject because the failed apis are not getting caught. Please check this
    return Promise.reject(err);
  }
);
