/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import ReactQuill from "react-quill";
import ReactPlayer from "react-player";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "./addProduct.css";
import ImageUpload from "../../assets/images/other-images/imageUpload.svg";
import UploadMediaModal from "../products/components/UploadFileModal";
import decryptFunction from "../common/decryptURL";
import { maxLengthCheck } from "../common/inputUtilities";
import { axiosInstance } from "../../utility/api";
import ThreeDeeViewer from "../common/ThreeDeeViewer/ThreeDeeViewer";
import Loader from "../common/Loader/Loader";
import OptionalCreator from "./components/optionalCreator";
import MultiSelectInput from "./components/multiSelect";
import BulkUpload from "./BulkUpload/BulkUpload";
import BulkUploadSection from "./BulkUpload/components/BulkUploadSection";
import moment from "moment";
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  radioGroupRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },

  formControlRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

// const styles = {
//   rootDrawer: {
//     zIndex: "200 !important",
//   },
// };

function AddProduct(props) {
  const {
    // style,
    state,
    onChangeDescription,
    handleChange,
    handleCreator,
    // handleImageUpload,
    handleMultiFileUpload,
    loadfile,
    categoryList,
    handleSubmitForm,
    setSelectedVideoFileType,
    setSelectedAudioType,
    isEdit,
    setError,
    isMainFileVideo,
    isMainFileAudio,
    isMainFile3D,
    disableSupportingDocs,
    isSuplimentaryFile1Video,
    isSuplimentaryFile2Video,
    isSuplimentaryFile3Video,
    isSuplimentaryFile4Video,
    handleRemoveFile,
    handleDeleteAll,
    isSuplimentaryFile13d,
    isSuplimentaryFile23d,
    isSuplimentaryFile33d,
    isSuplimentaryFile43d,
    resetCreatorOrArtist,
    onCloseDrawer,
  } = props;

  const classes = useStyles();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [previewVideoNeeded, setpreviewVideoNeeded] = useState(false);
  const [preview3DNeeded, setpreview3DNeeded] = useState(false);
  const [previewImgNeeded, setpreviewImgNeeded] = useState(false);
  const [open, setOpen] = React.useState(false);
  // const productConditionList = ["new", "used"];
  // const auctionTypeList = ["normal", "reverse"];
  // const auctionStatusList = ["active", "inactive"];
  const [EthValue, setEthValue] = useState(0);
  const [loader3D, setLoader3D] = useState(false);
  const [openingEthValue, setOpeningEthValue] = useState(0);
  const [creatorListMain, setCreatorListMain] = useState([]);
  const [creatorList, setCreatorList] = useState([]);
  const [createCollectionButtonClicked, setCreateCollectionButtonClicked] =
    useState(false);
  const [search, setSearch] = useState("");
  const reactQuillRef = React.useRef();
  const quilEditorText = reactQuillRef?.current?.getEditor();
  const [collectionImage, setCollectionImage] = useState("");
  const descriptionLength = quilEditorText?.getLength() - 1;

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };
  var descLength = 0;
  // let cameraPosition = {
  //   x: 150,
  //   y: 300,
  //   z: 350,
  // };
  // const onLoad = (e) => {
  //   console.log(e);
  // };

  // const onError = (e) => {
  //   console.log(e);
  // };

  const handleCreateCollection = () => {
    setCreateCollectionButtonClicked(true);
    setTimeout(() => {
      setCreateCollectionButtonClicked(false);
    }, 4000);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false, "", "");
  };

  const handleStartDateChange = (date) => {
    let event = { target: { name: "", value: "" } };
    event["target"]["name"] = "startDate";
    event["target"]["value"] = date;
    handleChange(event);
  };

  const handleEndingDateChange = (date) => {
    let event = { target: { name: "", value: "" } };
    event["target"]["name"] = "endingDate";
    event["target"]["value"] = date;
    handleChange(event);
  };

  const handleStartDateChangeAirdrop = (date) => {
    let event = { target: { name: "", value: "" } };
    event["target"]["name"] = "airdropStartDate";
    event["target"]["value"] = date;
    handleChange(event);
  };

  const handleEndingDateChangeAirdrop = (date) => {
    let event = { target: { name: "", value: "" } };
    event["target"]["name"] = "airdropEndDate";
    event["target"]["value"] = date;
    handleChange(event);
  };
  

  // useEffect(() => {
  //   if (state.shortVideoUrl && state.longVideoUrl) {
  //     setOpen(false);
  //   }
  //   if (state.audioUrl && state.audioThumbnail) {
  //     setOpen(false);
  //   }
  //   if (state.imageUrl && state.ipfsImageUrlPath) {
  //     setOpen(false);
  //   }
  // }, [state]);

  useEffect(() => {
    setpreviewVideoNeeded(isMainFileVideo);
  }, [isMainFileVideo]);

  // useEffect(() => {
  //   setpreview3DNeeded(isMainFile3D);
  // }, [isMainFile3D]);

  useEffect(() => {
    setpreviewImgNeeded(isMainFileAudio);
  }, [isMainFileAudio]);

  useEffect(() => {
    async function fetchData() {
      if (state?.regularPrice) {
        await axiosInstance
          .get(`/v1/admin/exchange/rate`, config)
          .then((res) => {
            setEthValue(state?.regularPrice * res?.data?.result?.exchangeRate);
          })
          .catch((error) => {
            console.log("Error getting ETH Value", error);
          });
      } else {
        setEthValue(0);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.regularPrice]);

  useEffect(() => {
    async function fetchData() {
      if (state?.auction?.openingPrice) {
        await axiosInstance
          .get(`/v1/admin/exchange/rate`, config)
          .then((res) => {
            setOpeningEthValue(
              state?.auction?.openingPrice * res?.data?.result?.exchangeRate
            );
          })
          .catch((error) => {
            console.log("Error while receiving eth value :", error);
          });
      } else {
        setOpeningEthValue(0);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.auction?.openingPrice]);

  useEffect(() => {
    getSubCategories(state?.categoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.categoryId]);

  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const formatInputDot = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "+" || e.key === "-" || e.key === ".";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 187 ||
        e.keyCode === 189 ||
        e.keyCode === 190 ||
        e.keyCode === 110;
    }
    return checkIfNum && e.preventDefault();
  };

  const getSubCategories = async (id) => {
    if (id) {
      await axiosInstance
        .get(`/v1/admin/category/${id}`, config)
        .then((res) => {
          setSubCategoryList(res?.data?.result?.subCategories);
        })
        .catch((err) => {
          console.log("Error while getting subcategories:", err);
        });
    } else {
      setSubCategoryList([]);
    }
  };

  const handleSelectChange = (e, type) => {
    console.log("handleSelectChange", e);
    let event = {
      target: {
        value: e?.id,
        name: type,
      },
    };
    handleChange(event);
  };

  const getCreatorList = async (search) => {
    if (config?.headers?.Authorization) {
      let query = `?page=1&limit=1000&search=${
        search || ""
      }&isBlock=&fromDate=&toDate=`;
      let newq = `?limit=10000&page=1`;
      // let query = {
      // 	page: 1,
      // 	limit: 10,
      // 	search: '',
      // 	isBlock: '',
      // 	fromDate: '',
      // 	toDate: ''
      // }
      // https://api-asly-dev1.devtomaster.com/admin/v1/admin/creator/getCreators?page=1&limit=10
      axiosInstance
        .get(`/v1/admin/creator/getCreators${newq}`, config)
        .then((res) => {
          console.log("/admin/user res", res);
          let arr = [];
          if (res?.data?.result?.users?.length) {
            res.data.result.users.forEach((el) => {
              let obj = {
                ...el,
                value: el?.id,
                label: el?.userName? el?.userName : el.firstName|| el.lastName? el?.firstName + " "+el?.lastName: "userId: "+ el?.id
                 
              };
              arr.push(obj);
            });
          }
          console.log("searach ", search, arr);
          if (!search) setCreatorListMain(arr);
          setCreatorList(arr);
        })
        .catch((err) => {
          console.log("/admin/user Error while getting subcategories:", err);
        });
    }
  };

  useEffect(() => {
    getCreatorList(search);
  }, [search]);

  useEffect(() => {
    let creatorArr = [];
    creatorArr = creatorListMain.filter(
      (el) => el?.id !== state?.creator?.id || state?.artist?.id
    );
    // if(state?.artist?.id) creatorArr = creatorListMain.filter(el => el?.id !== state?.artist?.id)
    // if(state?.creator?.id) creatorArr = creatorListMain.filter(el => el?.id !== state?.creator?.id)
    if (state?.creators?.length) {
      let arr = state?.creators.map((el) => {
        return el?.id;
      });
      creatorArr = creatorArr.filter(
        (val) => !arr.filter(Boolean).includes(val?.id)
      );
    }
    setCreatorList(creatorArr?.length === 0 ? creatorListMain : creatorArr);
  }, [state?.creators, state?.creator?.id, state?.artist?.id]);

  // const checkCharacterCount = (event) => {
  //   const descriptionLength = reactQuillRef.current.getEditor().getLength();
  //   if (descriptionLength > 1500 && event.key !== "Backspace")
  //     event.preventDefault();
  // };

  const isAuctionBidded = (auctions) => {
    let auctionBidded = false;
    for (let i = 0; i < auctions?.length; i++) {
      if (auctions?.[i].currentBidder) {
        auctionBidded = true;
      }
    }
    return auctionBidded;
  };
  const inCocreator = (id) => {
    let flag = 0;
    for (let i = 0; i < state.coCreators?.length; i++) {
      if (id == state.coCreators[i]?.id) {
        flag = 1;
        return true;
      }
    }
    if (!flag) {
      return false;
    }
  };

  const getCreatorName = () => {
    const templist = creatorList.filter((item) => {
      if (
        item?.id != state.artist?.id &&
        item.status === "verified" &&
        !inCocreator(item?.id)
      ) {
        return item;
      }
    });
    return templist;
  };

  const getArtistnameList = () => {
    const templist = creatorList.filter((item) => {
      if (
        item?.id != state.creator?.id &&
        item.status === "verified" &&
        !inCocreator(item?.id)
      ) {
        return item;
      }
    });
    return templist;
  };

  return (
    <>
      <div className="container tm-mt-big tm-mb-big">
        <div className="row">
          <div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
            <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
              <div
                className="container tm-mt-big tm-mb-big"
                style={{ paddingLeft: 0 }}
              >
                <div className="row">
                  <div className="col-12">
                    <h2 className="tm-block-title d-inline-block">
                      {isEdit == true ? "Edit" : "Add"} Asset
                    </h2>
                  </div>
                </div>
              </div>
              {/* <div className="container tm-mt-big tm-mb-big"> */}
              <div className="row tm-edit-product-row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <form action="" className="tm-edit-product-form">
                    {isEdit == true ? (
                      <div className="form-group mb-3">
                        <label htmlFor="name">Asset ID</label>
                        <span className="colorRed">*</span>
                        <input
                          type="text"
                          name="assetID"
                          className="form-control validate"
                          value={state?.id}
                          disabled="true"
                        />
                      </div>
                    ) : null}
                    <div className="form-group mb-3">
                      <label htmlFor="category">Sale Type</label>
                      <span className="colorRed">*</span>
                      <FormControl
                        component="fieldset"
                        classes={{
                          root: classes.formControlRoot,
                        }}
                        disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                      >
                        <RadioGroup
                          classes={{
                            root: classes.radioGroupRoot, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                          }}
                          name="saleType"
                          value={state.saleType}
                          onChange={(e) => handleChange(e)}
                        >
                          {state.saleType !== 'free' && <>
                          <FormControlLabel
                            value="fixed"
                            control={<Radio />}
                            label="Fixed Price"
                          />
                          <FormControlLabel
                            value="auction"
                            control={<Radio />}
                            label="Auction"
                          />
                          <FormControlLabel
                            value="collection"
                            control={<Radio />}
                            label="Collection"
                          /></>}

                          {state.saleType === 'free' && <>
                          <FormControlLabel
                            value="free"
                            control={<Radio />}
                            label="Free"
                          />
                          </>
                        }

                        </RadioGroup>
                      </FormControl>
                    </div>
                    {["fixed", "auction", "free"].includes(state.saleType) ? (
                      <>
                        <div className="form-group mb-3">
                          <label htmlFor="name">Asset Name</label>
                          <span className="colorRed">*</span>
                          <input
                            type="text"
                            name="name"
                            className="form-control validate"
                            value={state.name}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                            disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                          />
                          <div className="charactersRemaining">
                            <span>
                              ({state?.name ? 90 - state?.name?.length : "90"}{" "}
                              characters remaining)
                            </span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="name">Asset Slug</label>
                          <span className="colorRed">*</span>
                          <input
                            type="text"
                            name="slug"
                            className="form-control validate"
                            value={state.slug}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                            disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                          />
                          <div className="charactersRemaining">
                            <span>
                              ({state?.name ? 90 - state?.name?.length : "90"}{" "}
                              characters remaining)
                            </span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="description">Description</label>
                          <span className="colorRed">*</span>
                          <ReactQuill
                            // onKeyDown={checkCharacterCount}
                            ref={reactQuillRef}
                            value={state.description}
                            onChange={(e) => {
                              const quilEditor =
                                reactQuillRef?.current?.getEditor();
                              const descriptionLength = quilEditor?.getLength();
                              if (reactQuillRef.current) {
                                descLength = descriptionLength;
                                if (descriptionLength < 1500) {
                                  onChangeDescription(e);
                                } else {
                                  quilEditor.deleteText(
                                    1500,
                                    quilEditor.getLength()
                                  );
                                  console.log(
                                    "description",
                                    reactQuillRef.current.getEditorContents()
                                  );
                                  onChangeDescription(
                                    reactQuillRef.current.getEditorContents()
                                  );
                                }
                              } else {
                                onChangeDescription(e);
                              }
                            }}
                          />
                          {/* <CKEditor
                        style={style}
                        // config={{
                        //   toolbar: [
                        //     "bold",
                        //     "italic",
                        //     "NumberedList",
                        //     "BulletedList",
                        //     "|",
                        //     "undo",
                        //     "redo",
                        //   ],
                        // }}
                        // enforceFocus={false}
                        data={state.description}
                        editor={ClassicEditor}
                        onChange={onChangeDescription}
                      /> */}
                          <div className="charactersRemaining">
                            <span>
                              (
                              {state?.description
                                ? 1500 -
                                    (descriptionLength || state?.description) >=
                                  0
                                  ? 1500 -
                                    (descriptionLength || state?.description)
                                  : 0
                                : "1500"}{" "}
                              characters remaining)
                            </span>
                          </div>
                        </div>
                        <div className="row d-flex-aic separator">
                      
                          
                      
                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label htmlFor="stock">Creator Name</label>
                            <span className="colorRed">*</span>
                            <Select
                              classNamePrefix="creator-select-input"
                              value={
                                creatorListMain.find(
                                  (el) => el?.id === state?.creator?.id
                                ) || null
                              }
                              options={getCreatorName()}
                              onChange={(e) =>
                                handleSelectChange(e, "creatorId")
                              }
                              isDisabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                              // onInputChange={(e) => setSearch(e)}
                            />
                          </div>
                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label className="ws-no-wrap" htmlFor="stock">
                              Commission Percentage
                            </label>
                            <span className="colorRed">*</span>
                            <div className="displayFlexBaseline">
                              <div className="percent-symbol">%</div>
                              <span className="currencyInput w-100 removePadding">
                                <input
                                  type="number"
                                  name="creator.royaltyCommissionPercentage"
                                  min="0"
                                  className="form-control validate percentageText"
                                  maxLength="12"
                                  disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  value={
                                    state?.creator?.royaltyCommissionPercentage
                                  }
                                  onChange={(e) => {
                                    if (/[0-9]/i.test(Number(e.target.value))) {
                                      handleChange(e);
                                    }
                                  }}
                                  required
                                />
                              </span>
                            </div>
                          </div>   
                           {state.saleType != 'free' && <>
                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label className="ws-no-wrap" htmlFor="stock">
                              Primary Earnings
                            </label>
                            <span className="colorRed">*</span>
                            <div className="displayFlexBaseline">
                              <div className="percent-symbol">%</div>
                              <span className="currencyInput w-100 removePadding">
                                <input
                                  type="number"
                                  name="creator.primarySalePercentage"
                                  min="0"
                                  className="form-control validate percentageText"
                                  maxLength="12"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  disabled={isEdit ? true : false}
                                  value={state?.creator?.primarySalePercentage}
                                  onChange={(e) => {
                                    console.log("changed");
                                    console.log(
                                      state.creator?.primarySalePercentage
                                    );
                                    if (e.target.value >= 0) {
                                      handleChange(e);
                                    }
                                  }}
                                  onKeyDown={formatInput}
                                  required
                                />
                              </span>
                            </div>
                          </div>
                          <div
                            className={
                              state.creator.id
                                ? "form-group mb-3 col-xs-12 col-sm-5"
                                : "form-group mb-3 col-xs-12 col-sm-6"
                            }
                          >
                            <label htmlFor="stock">Cap Amount (USD)</label>
                            {/* <span className="colorRed">*</span> */}
                            <input
                              type="number"
                              name="creator.royaltyCommissionCapAmount"
                              min="0"
                              className="form-control validate"
                              maxLength="12"
                              placeholder="USD"
                              value={state?.creator?.royaltyCommissionCapAmount}
                              disabled={isEdit ? true : false}
                              onWheel={(event) => event.currentTarget.blur()}
                              onChange={(e) => {
                                if (/[0-9]/i.test(Number(e.target.value))) {
                                  handleChange(e);
                                }
                              }}
                              required
                            />
                          </div></>}
                          {state.creator?.id && (
                            <>
                              {isEdit ? null : (
                                <a
                                  className="cursor-pointer"
                                  onClick={() =>
                                    resetCreatorOrArtist("creator")
                                  }
                                >
                                  X
                                </a>
                              )}
                            </>
                          )}
                        </div>
                        <div className="row d-flex-aic separator">

                  
                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label htmlFor="stock">Artist Name</label>
                            <Select
                              classNamePrefix="creator-select-input"
                              value={
                                creatorListMain.find(
                                  (el) => el?.id === state?.artist?.id
                                ) || null
                              }
                              options={getArtistnameList()}
                              onChange={(e) =>
                                handleSelectChange(e, "artistId")
                              }
                              isDisabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                              // onInputChange={(e) => setSearch(e)}
                            />
                          </div>
                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label className="ws-no-wrap" htmlFor="stock">
                              Commission Percentage
                            </label>
                            {state.saleType !== 'free' && <span className="colorRed">*</span>}

                            <div className="displayFlexBaseline">
                              <div className="percent-symbol">%</div>
                              <span className="currencyInput w-100 removePadding">
                                <input
                                  type="number"
                                  name="artist.royaltyCommissionPercentage"
                                  min="0"
                                  className="form-control validate percentageText"
                                  maxLength="12"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                                  value={
                                    state?.artist?.royaltyCommissionPercentage
                                  }
                                  onChange={(e) => {
                                    if (/[0-9]/i.test(Number(e.target.value))) {
                                      handleChange(e);
                                    }
                                  }}
                                  required = {state.saleType!='free'}
                                />
                              </span>
                            </div>
                          </div>
                                
                          {state.saleType != 'free' && <>
                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label className="ws-no-wrap" htmlFor="stock">
                              Primary Earnings
                            </label>
                            <div className="displayFlexBaseline">
                              <div className="percent-symbol">%</div>
                              <span className="currencyInput w-100 removePadding">
                                <input
                                  type="number"
                                  name="artist.primarySalePercentage"
                                  min="0"
                                  className="form-control validate percentageText"
                                  maxLength="12"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  disabled={isEdit ? true : false}
                                  value={state?.artist?.primarySalePercentage}
                                  onChange={(e) => {
                                    if (/[0-9]/i.test(Number(e.target.value))) {
                                      handleChange(e);
                                    }
                                  }}
                                  required
                                />
                              </span>
                            </div>
                          </div>
                          <div
                            className={
                              state.artist.id
                                ? "form-group mb-3 col-xs-12 col-sm-5"
                                : "form-group mb-3 col-xs-12 col-sm-6"
                            }
                          >
                            <label htmlFor="stock">Cap Amount (USD)</label>
                            <input
                              type="number"
                              name="artist.royaltyCommissionCapAmount"
                              min="0"
                              className="form-control validate"
                              maxLength="12"
                              value={state?.artist?.royaltyCommissionCapAmount}
                              disabled={isEdit ? true : false}
                              onWheel={(event) => event.currentTarget.blur()}
                              onChange={(e) => {
                                if (/[0-9]/i.test(Number(e.target.value))) {
                                  handleChange(e);
                                }
                              }}
                              required
                            />
                          </div></>}
                          {state.artist?.id && (
                            <>
                              {isEdit ? null : (
                                <a
                                  className="cursor-pointer"
                                  onClick={() => resetCreatorOrArtist("artist")}
                                >
                                  X
                                </a>
                              )}
                            </>
                          )}
                        </div>
                        {/* <div className="form-group mb-3">
                          <label htmlFor="description">
                            Artist Description
                          </label>
                          <textarea
                            className="form-control validate"
                            rows="3"
                            required=""
                            spellCheck="false"
                            maxLength="1500"
                            onInput={maxLengthCheck}
                            name="creatorDescription"
                            value={state.creatorDescription}
                            onChange={(e) => handleChange(e)}
                          ></textarea>
                          <div className="charactersRemaining">
                            <span>
                              (
                              {state?.creatorDescription
                                ? 1500 - state?.creatorDescription?.length
                                : "1500"}{" "}
                              characters remaining)
                            </span>
                          </div>
                        </div> */}

                        <OptionalCreator
                          handleCreator={handleCreator}
                          creatorList={creatorList}
                          setSearch={setSearch}
                          state={state}
                          isEdit={isEdit}
                        />
                        {/* <div className="form-group mb-3">
                      <label htmlFor="description">Description MINT</label>
                      <textarea
                        className="form-control validate"
                        rows="3"
                        required=""
                        spellCheck="false"
                        maxLength="1000"
                        onInput={maxLengthCheck}
                        name="descriptionMint"
                        value={state?.descriptionMint}
                        onChange={(e) => handleChange(e)}
                      ></textarea>
                      <div className="charactersRemaining">
                        <span>
                          (
                          {state?.descriptionMint
                            ? 1000 - state?.descriptionMint?.length
                            : "1000"}{" "}
                          characters remaining)
                        </span>
                      </div>
                    </div> */}
               <div className="form-group mb-3">
                          <label htmlFor="stock">Royality Commission</label>
                          <span className="colorRed">*</span>
                          <div className="displayFlexBaseline">
                            <span className="currencyInput">
                              %
                              <input
                                type="number"
                                name="royaltyCommission"
                                min="0"
                                className=""
                                value={state?.royaltyCommission}
                                onWheel={(event) => event.currentTarget.blur()}
                                onKeyDown={formatInput}
                                onChange={(e) => {
                                  if (e.target.value >= 0) {
                                    handleChange(e);
                                  }
                                }}
                                disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                                required
                              />
                            </span>
                          </div>
                        </div>
                        {["fixed" , "free"].includes(state.saleType) ? (
                          <div className="row">
                            {(state.saleType != "fixed" && state.saleType != "free")? (
                              <div className="form-group mb-3 col-xs-12 col-sm-6">
                                <label htmlFor="stock">Current Edition</label>

                                <span className="colorRed">*</span>
                                <div className="displayFlexBaseline">
                                  {" "}
                                  <input
                                    type="number"
                                    name="currentEdition"
                                    min="0"
                                    className="form-control validate"
                                    value={state?.currentEdition}
                                    onKeyDown={formatInputDot}
                                    onChange={(e) => {
                                      if (e.target.value >= 0) {
                                        handleChange(e);
                                      }
                                    }}
                                    onWheel={(event) =>
                                      event.currentTarget.blur()
                                    }
                                    disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                                    required
                                  />
                                </div>
                              </div>
                            ) : null}
                            <div className="form-group mb-3 col-xs-12 col-sm-6">
                              <label htmlFor="stock">Total Edition</label>

                              <span className="colorRed">*</span>

                              <input
                                type="number"
                                name="totalEdition"
                                min="0"
                                className="form-control validate"
                                value={state?.totalEdition}
                                onWheel={(event) => event.currentTarget.blur()}
                                onKeyDown={formatInputDot}
                                onChange={(e) => {
                                  if (e.target.value >= 0) {
                                    handleChange(e);
                                  }
                                }}
                                disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                                required
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {state.saleType != "fixed" ? (
                      <div className="form-group mb-3">
                        <label htmlFor="category">Mint Admin Edition</label>
                        <span className="colorRed">*</span>
                        <FormControl
                          component="fieldset"
                          size="small"
                          classes={{
                            root: classes.formControlRoot,
                          }}
                        >
                          <RadioGroup
                            classes={{
                              root: classes.radioGroupRoot,
                              label: classes.label,
                            }}
                            name="isEditionMintToAdmin"
                            value={state?.isEditionMintToAdmin}
                            onChange={(e) => handleChange(e)}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Yes"
                              disabled={isEdit ? true : false}
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="No"
                              disabled={isEdit ? true : false}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    ) : null} */}
                        <div className="row">
                          {state.saleType == "fixed" ? (
                            <>
                              <div className="form-group mb-3 col-xs-12 col-sm-6">
                                <label htmlFor="stock">
                                  Unit Available For Sale.
                                </label>
                                <div className="displayFlexBaseline">
                                  {" "}
                                  <input
                                    type="number"
                                    name="unitAvailableSale"
                                    min="0"
                                    className="form-control validate"
                                    value={
                                      !isEdit
                                        ? parseInt(state?.totalEdition) -
                                          parseInt(state?.soldEdition || 0)
                                        : parseInt(state.availableEdition || 1)
                                    }
                                    onKeyDown={formatInput}
                                    onChange={(e) => {
                                      if (e.target.value >= 0) {
                                        handleChange(e);
                                      }
                                    }}
                                    disabled={true}
                                    required
                                  />
                                </div>
                              </div>

                              <div className="form-group mb-3 col-xs-12 col-sm-6">
                                <label htmlFor="stock">Units In Stock</label>
                                <span className="colorRed">*</span>
                                <input
                                  type="number"
                                  name="quantity"
                                  min="0"
                                  className="form-control validate"
                                  maxLength="12"
                                  onInput={maxLengthCheck}
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  value={state.quantity}
                                  onKeyDown={formatInputDot}
                                  onChange={(e) => handleChange(e)}
                                  required
                                />
                              </div>
                            </>
                          ) : null}
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="category">Sale Status</label>
                          <span className="colorRed">*</span>
                          <FormControl
                            component="fieldset"
                            size="small"
                            classes={{
                              root: classes.formControlRoot,
                            }}
                          >
                            <RadioGroup
                              classes={{
                                root: classes.radioGroupRoot,
                                label: classes.label,
                              }}
                              name="status"
                              value={state.status}
                              onChange={(e) => handleChange(e)}
                             
                            >
                              <FormControlLabel
                                value="active"
                                control={<Radio />}
                                label="Active"
                                disabled = {(state.saleType == 'free' && isEdit && (!moment.utc(state?.airdropStartDate).isBefore(moment.utc()) || moment.utc(state?.airdropEndDate).isBefore(moment.utc()))) || 
                                            (state.saleType == 'auction' && isEdit && (!moment.utc(state?.auction?.startDate).isBefore(moment.utc()) || moment.utc(state?.auction?.endingDate).isBefore(moment.utc())))}
                              />
                              {console.log(moment.utc())}
                              <FormControlLabel
                                value="inactive"
                                control={<Radio />}
                                label="Inactive"
                                disabled = {(state.saleType == 'free' && isEdit && (!moment.utc(state?.airdropStartDate).isBefore(moment.utc()) || moment.utc(state?.airdropEndDate).isBefore(moment.utc()))) || 
                                (state.saleType == 'auction' && isEdit && (!moment.utc(state?.auction?.startDate).isBefore(moment.utc()) && moment.utc(state?.auction?.endingDate).isBefore(moment.utc())))}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        {["fixed", "collection"].includes(state.saleType) ? (
                          // <div className="row">
                          <div className="form-group mb-3">
                            <label htmlFor="expire_date">Price</label>

                            <span className="colorRed">*</span>
                            <div className="addProductFlexRow">
                              <span className="currencyInput">
                                $
                                <input
                                  type="text"
                                  name="regularPrice"
                                  min="0"
                                  onKeyDown={formatInput}
                                  value={state.regularPrice}
                                  onChange={(e) => {
                                    if (/[0-9]/i.test(Number(e.target.value))) {
                                      handleChange(e);
                                    }
                                  }}
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  required
                                />
                              </span>

                              {/* <div className="ethValueDisplayer">{`${EthValue.toFixed(
                                4
                              )} MATIC`}</div> */}
                              <div className="ethValueDisplayer">{`${state.regularPrice ? state.regularPrice : 0} USDC`}</div>
                            </div>
                            
                            {/* </div> */}
                            {/* <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="expire_date">Sale Price</label>
                          <div className="addProductFlexRow">
                            <input
                              type="number"
                              name="salePrice"
                              min="0"
                              className="form-control validate"
                              onKeyDown={formatInput}
                              value={state.salePrice}
                              onChange={(e) => {
                                // if (e.target.value != 0 || e.target.value >= -0 || e.target.value == "") {
                                handleChange(e);
                                // }
                              }}
                              required
                            />
                            <span className="percentageImgContainer">$</span>
                          </div>
                        </div> */}
                            {/* </div> */}
                          </div>
                        ) : null}
                        {/* {state.saleType == "fixed" ? (
                      <div className="form-group mb-3">
                        <label htmlFor="stock">Units In Stock</label>
                        <span className="colorRed">*</span>
                        <input
                          type="number"
                          name="quantity"
                          min="0"
                          className="form-control validate"
                          value={state.quantity}
                          onKeyDown={formatInputDot}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                    ) : null} */}
                        <div className="row">
                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label htmlFor="category">Category</label>
                            <span className="colorRed">*</span>
                            <select
                              className="custom-select tm-select-accounts"
                              name="categoryId"
                              id="categoryId"
                              value={`${state.categoryId}`}
                              onChange={(e) => handleChange(e)}
                              disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                              required
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {categoryList?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label htmlFor="category">Sub Category</label>
                            <span className="colorRed">*</span>
                            <select
                              className="custom-select tm-select-accounts"
                              name="subCategoryId"
                              id="subCategoryId"
                              value={`${state.subCategoryId}`}
                              onChange={(e) => handleChange(e)}
                              disabled={(state.saleType != 'free' && isEdit == true)? true : false}
                              required
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {subCategoryList?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/* <div className="form-group mb-3">
                          <label htmlFor="description">Short Description</label>
                          <textarea
                            className="form-control validate"
                            rows="3"
                            required=""
                            spellCheck="false"
                            maxLength="1500"
                            onInput={maxLengthCheck}
                            name="shortDescription"
                            value={state.shortDescription}
                            onChange={(e) => handleChange(e)}
                          ></textarea>{" "}
                          <div className="charactersRemaining">
                            <span>
                              (
                              {state?.shortDescription
                                ? 1500 - state?.shortDescription?.length
                                : "1500"}{" "}
                              characters remaining)
                            </span>
                          </div>
                        </div> */}
                      </>
                    ) : (
                      <BulkUpload
                        state={state}
                        handleChangeCoCreator={handleChange}
                        collectionImage={collectionImage}
                        createCollectionButtonClicked={
                          createCollectionButtonClicked
                        }
                        onCloseDrawer={onCloseDrawer}
                      />
                    )}
                  </form>
                </div>
                {["fixed", "auction", "free"].includes(state.saleType) ? (
                  <div className="col-xl-6 col-lg-6 col-md-12 mx-auto mb-4">
                    {/* Audio file display */}
                    {state?.ipfsAudioThumbnailPath && (
                      <div
                        className={`tm-product-img-dummy mx-auto addProductFlexCenter height41p imgGreyBg cursorPointer`}
                      >
                        <img
                          alt="preview"
                          src={
                            state?.ipfsAudioThumbnailPath
                              ? state.ipfsAudioThumbnailPath
                              : ImageUpload
                          }
                          className="previewImg"
                          nb8h
                        />
                      </div>
                    )}

                    {/* 3D file preview section */}
                    {loader3D && state?.is3dFile ? (
                      <div className="fileUploadPreviewContent height240px">
                        <Loader
                          loaderInfo="This may take a while to render 3D preview."
                          customize={true}
                        />
                      </div>
                    ) : null}

                    {state?.ipfs3dUrl && (
                      <div
                      // style={{ display: loader ? "none" : "" }}
                      >
                        <ThreeDeeViewer
                          id={state?.ipfs3dId}
                          setLoader3D={(value) => setLoader3D(value)}
                        />
                      </div>
                    )}

                    {/* Video/Audio Preview section */}
                    {state?.ipfsLongVideoUrlPath && !state?.ipfs3dUrl ? (
                      // ||
                      // (state?.ipfsAudioUrlPath && !state?.ipfs3dUrl)
                      <ReactPlayer
                        url={
                          state.ipfsLongVideoUrlPath
                            ? !isEdit
                              ? decryptFunction(state.ipfsLongVideoUrlPath)
                              : state.ipfsLongVideoUrlPath
                            : !isEdit
                            ? decryptFunction(state?.ipfsAudioUrlPath)
                            : state?.ipfsAudioUrlPath
                        }
                        width="100%"
                        height={state?.ipfsAudioUrlPath ? "86px" : "300px"}
                        controls={true}
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                              ImageUpload,
                            },
                          },
                        }}
                        // playIcon="true"
                      />
                    ) : state?.ipfsAudioUrlPath && !state?.ipfs3dUrl ? (
                      <>
                        <audio
                          controls
                          controlsList="nodownload"
                          style={{ width: "100%" }}
                        >
                          <source
                            src={
                              !isEdit
                                ? decryptFunction(state?.ipfsAudioUrlPath)
                                : state?.ipfsAudioUrlPath
                            }
                          ></source>
                        </audio>
                      </>
                    ) : (
                      (!state?.ipfs3dUrl || state?.ipfs3dUrl == undefined) && (
                        <div
                          className={`tm-product-img-dummy mx-auto addProductFlexCenter height41p imgGreyBg cursorPointer ${
                            state?.ipfsImageUrlPath ? "" : "addProduct-bg-grey"
                          }`}
                          onClick={handleClickOpen}
                          style={{
                            display: state?.ipfsAudioThumbnailPath
                              ? "none"
                              : "block",
                          }}
                        >
                          <img
                            alt="preview"
                            src={
                              state?.ipfsImageUrlPath
                                ? !isEdit
                                  ? state.ipfsImageUrlPath
                                  : state.ipfsImageUrlPath
                                : ImageUpload
                            }
                            className="previewImg"
                          />
                        </div>
                      )
                    )}
                    <div className="custom-file mt-3 mb-3">
                      <input
                        type="file"
                        id="main_file"
                        style={{ display: "none" }}
                        onClick={() => {
                          document.getElementById(`main_file`).value = null;
                        }}
                        onChange={loadfile}
                        // accept="image/*"
                      />
                      <input
                        type="file"
                        id="supporting_file1"
                        style={{ display: "none" }}
                        onClick={() => {
                          document.getElementById(`supporting_file1`).value =
                            null;
                        }}
                        onChange={loadfile}
                        // accept="audio/*"
                      />
                      <input
                        type="file"
                        id="supporting_file2"
                        style={{ display: "none" }}
                        onClick={() => {
                          document.getElementById(`supporting_file2`).value =
                            null;
                        }}
                        onChange={loadfile}
                        // accept="video/*"
                      />
                      <input
                        type="file"
                        id="supporting_file3"
                        style={{ display: "none" }}
                        onClick={() => {
                          document.getElementById(`supporting_file3`).value =
                            null;
                        }}
                        onChange={loadfile}
                        // accept="video/*"
                      />
                      <input
                        type="file"
                        id="supporting_file4"
                        style={{ display: "none" }}
                        onClick={() => {
                          document.getElementById(`supporting_file4`).value =
                            null;
                        }}
                        onChange={loadfile}
                        // accept="video/*"
                      />
                      <input
                        type="button"
                        className="btn btn-primary btn-block mx-auto"
                        value="UPLOAD"
                        // onClick={handleImageUpload}
                        onClick={handleClickOpen}
                      />
                    </div>

                    <div>
                      
                        <FormControlLabel
                          control={
                            <Switch
                              // checked={state.checkedB}
                              onChange={handleChange}
                              name="isResellable"
                              defaultChecked={state?.isResellable}
                            />
                          }
                          label="Resellable"
                        />
                      
                    </div>
                    <div>
                    {state.saleType == "free" && (
                          
                               <FormControlLabel
                          control={
                            <Switch
                              // checked={state.checkedB}
                              onChange={handleChange}
                              name="isAutoApprove"
                              defaultChecked={state?.isAutoApprove}
                            />
                          }
                          label="Enable Auto Approve"
                        />                           
                          )}                       
                      
                    </div>

                    <div className="row disableAndHideDiv">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label htmlFor="expire_date">Reserve Price</label>
                        <span className="colorRed">*</span>
                        <div className="addProductFlexRow marginLeft6px">
                          <span className="currencyInput">
                            $
                            <input
                              type="text"
                              name="openingPrice11"
                              min="0"
                              className="form-control validate"
                              onKeyDown={formatInput}
                              value={50}
                              onChange={(e) => {
                                if (/[0-9]/i.test(Number(e.target.value))) {
                                  // if (event.which === 38 || event.which === 4) {
                                  handleChange(e);
                                }
                                // if (e.target.value > 0 || e.target.value == "") {
                                //   handleChange(e);
                                // }
                              }}
                              onWheel={(event) => event.currentTarget.blur()}
                              disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                              required
                            />
                          </span>
                          {/* <input
                          type="number"
                          name="openingPrice11"
                          min="0"
                          className="form-control validate"
                          onKeyDown={formatInputDot}
                          value={50}
                          onChange={(e) => {
                            if (e.target.value > 0 || e.target.value == "") {
                              handleChange(e);
                            }
                          }}
                          required
                        /> */}
                          {/* <span className="percentageImgContainer">$</span> */}
                        </div>
                      </div>
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label htmlFor="expire_date">
                          Lowest Price to accept
                        </label>
                        {/* <span className="colorRed">*</span> */}
                        <div className="addProductFlexRow">
                          <input
                            type="number"
                            name="lowestPriceToAccept"
                            id="lowestPriceToAccept11"
                            min="0"
                            className="form-control validate"
                            onKeyDown={formatInput}
                            value={50}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                          <span className="percentageImgContainer">$</span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row"> */}
                    {/* <div className="form-group mb-3 col-xs-12 col-sm-6">
                      <label htmlFor="category">Downloadable</label>
                      <FormControl
                        component="fieldset"
                        size="small"
                        classes={{
                          root: classes.formControlRoot,
                        }}
                      >
                        <RadioGroup
                          classes={{
                            root: classes.radioGroupRoot,
                            label: classes.label,
                          }}
                          name="isDownloadable"
                          value={state?.isDownloadable}
                          onChange={(e) => handleChange(e)}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                            checked={
                              state?.isDownloadable == "true" ? false : true
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div> */}
                    {/* </div> */}
                    {/* {state.saleType == "auction" ? (
										<div className="form-group mb-3">
											<label htmlFor="category">Show on Carousel</label>
											<span className="colorRed">*</span>
											<FormControl
												component="fieldset"
												classes={{
													root: classes.formControlRoot,
												}}
											>
												<RadioGroup
													classes={{
														root: classes.radioGroupRoot, 
														label: classes.label,
													}}
													name="isCarrosel"
													value={state?.isCarrosel}
													onChange={(e) => handleChange(e)}
												>
													<FormControlLabel
														value={"true"}
														control={<Radio />}
														label="Yes"
													/>
													<FormControlLabel
														value={"false"}
														control={<Radio />}
														label="No"
													/>
												</RadioGroup>
											</FormControl>
										</div>
									) : null} */}

                    {/* <div className="row">
                    <div className="form-group mb-3 col-xs-12 col-sm-6"></div>
                    <div className="form-group mb-3 col-xs-12 col-sm-6"></div>
                    {state.saleType == "auction" ? (
                      <>
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="category">Product Condition</label>
                          <span className="colorRed">*</span>
                          <select
                            className="custom-select tm-select-accounts"
                            name="productCondition"
                            id="productCondition"
                            value={`${state.productCondition}`}
                            onChange={(e) => handleChange(e)}
                            required
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {productConditionList?.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="category">Auction Type</label>
                          <span className="colorRed">*</span>
                          <select
                            className="custom-select tm-select-accounts"
                            name="auctionType"
                            id="auctionType"
                            value={`${state.auctionType}`}
                            onChange={(e) => handleChange(e)}
                            required
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {auctionTypeList?.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : null}
                  </div> */}

                    {state.saleType == "auction" ? (
                      <div className="row">
                        <div className="form-group mb-3 paddingLeft14px">
                          <label htmlFor="expire_date">Reserve Price</label>
                          <span className="colorRed">*</span>
                          <div className="addProductFlexRow marginLeft6px">
                            <span className="currencyInput">
                              $
                              <input
                                type="text"
                                name="openingPrice"
                                onKeyDown={formatInput}
                                value={state?.auction?.openingPrice}
                                onChange={(e) => {
                                  if (/[0-9]/i.test(Number(e.target.value))) {
                                    handleChange(e);
                                  }
                                }}
                                required
                                disabled={
                                  state?.purchasedBy ||
                                  isAuctionBidded(state?.auctions)
                                    ? true
                                    : false
                                }
                              />
                            </span>
                            {/* <div className="ethValueDisplayer">{`${openingEthValue.toFixed(
                              4
                            )} MATIC`}</div> */}
                          <div className="ethValueDisplayer">{`${state?.auction?.openingPrice ? state?.auction?.openingPrice : 0} USDC`}</div>
                          </div>

                        </div>
                        {/* <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label htmlFor="expire_date">Lowest Price to accept</label>
                        <div className="addProductFlexRow">
                          <input
                            type="number"
                            name="lowestPriceToAccept"
                            id="lowestPriceToAccept"
                            min="0"
                            className="form-control validate"
                            onKeyDown={formatInput}
                            value={state?.auction?.lowestPriceToAccept}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                          <span className="percentageImgContainer">$</span>
                        </div>
                      </div> */}
                      </div>
                    ) : null}
                    {/* {state.saleType == "auction" ? (
                    <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label htmlFor="expire_date">Bid Increment</label>
                        <div className="addProductFlexRow">
                          <input
                            type="number"
                            name="bidIncrement"
                            min="0"
                            className="form-control validate"
                            onKeyDown={formatInput}
                            value={state?.auction?.bidIncrement}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                          <span className="percentageImgContainer">$</span>
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                    {/* {state.saleType == "auction" ? (
                    <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label htmlFor="expire_date">Buy Now Price </label>
                    
                        <div className="addProductFlexRow">
                          <input
                            type="number"
                            name="auctionBuyNowPrice"
                            min="0"
                            className="form-control validate"
                            onKeyDown={formatInput}
                            value={state.auctionBuyNowPrice}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                          <span className="percentageImgContainer">$</span>
                        </div>
                      </div>
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label htmlFor="category">No of Bids</label>
                        <input
                          type="number"
                          name="numberOfBid"
                          min="0"
                          className="form-control validate"
                          onKeyDown={formatInput}
                          value={state?.auction?.numberOfBid}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        />
                      </div>
                    </div>
                  ) : null} */}
                    {["auction"].includes(state.saleType) ? (
                      <div className="row">
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="expire_date">Start Date </label>
                          <span className="colorRed">*</span>
                          {/* <TextField
                          id="datetime-local"
                          name="startDate"
                          label="Start Date"
                          type="datetime-local"
                          value={state.startDate}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        /> */}
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              variant="inline"
                              value={state?.auction?.startDate}
                              disablePast={true}
                              format="dd/MM/yyyy hh:mm a"
                              // onChange={handleDateChange}
                              onChange={(e) => {
                                handleStartDateChange(e);
                              }}
                              disabled={isEdit == true ? true : false}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="category">End Date</label>
                          <span className="colorRed">*</span>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              variant="inline"
                              value={state?.auction?.endingDate}
                              disablePast={true}
                              format="dd/MM/yyyy hh:mm a"
                              // onChange={handleDateChange}
                              onChange={handleEndingDateChange}
                              disabled={isEdit == true ? true : false}
                            />
                          </MuiPickersUtilsProvider>
                          {/* <TextField
                          id="datetime-local"
                          name="endingDate"
                          label="End Date"
                          type="datetime-local"
                          value={state.endingDate}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        /> */}
                        </div>
                      </div>
                    ) : null}
                     {["free"].includes(state.saleType) ? (
                      <div className="row">
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="expire_date">Start Date </label>
                          <span className="colorRed">*</span>
                          {/* <TextField
                          id="datetime-local"
                          name="startDate"
                          label="Start Date"
                          type="datetime-local"
                          value={state.startDate}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        /> */}
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              variant="inline"
                              value={state?.airdropStartDate}
                              disablePast={true}
                              format="dd/MM/yyyy hh:mm a"
                              // onChange={handleDateChange}
                              onChange={(e) => {
                                handleStartDateChangeAirdrop(e);
                              }}
                              disabled={(moment(state?.airdropStartDate).format() < moment().format() && isEdit == true) ? true : false}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="category">End Date</label>
                          <span className="colorRed">*</span>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              variant="inline"
                              value={state?.airdropEndDate}
                              disablePast={true}
                              format="dd/MM/yyyy hh:mm a"
                              // onChange={handleDateChange}
                              onChange={handleEndingDateChangeAirdrop}
                              disabled={(state.saleType != 'free' && isEdit == true) ? true : false}
                            />
                          </MuiPickersUtilsProvider>
                          {/* <TextField
                          id="datetime-local"
                          name="endingDate"
                          label="End Date"
                          type="datetime-local"
                          value={state.endingDate}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        /> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <BulkUploadSection
                    setCollectionImage={setCollectionImage}
                    collectionImage={collectionImage}
                  />
                )}
              </div>
              {/* </div> */}

              <div className="col-12" style={{ marginBottom: 20, padding: 0 }}>
                {["fixed", "auction","free"].includes(state.saleType) ? (
                  <button
                    type="submit"
                    className="btn btn-primary btn-block text-uppercase"
                    onClick={handleSubmitForm}
                    disabled={
                      state.name ||
                      state.description ||
                      state.salePrice ||
                      state.quantity ||
                      state.imageUrl
                        ? false
                        : true
                    }
                  >
                    {isEdit == true ? "Save" : "Add"} Asset Now
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary btn-block text-uppercase"
                    onClick={handleCreateCollection}
                  >
                    {isEdit == true ? "Save" : "Add"} Collection Now
                  </button>
                )}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  maxWidth="md"
                  // style={{
                  //   position: "fixed",
                  //   zIndex: 300,
                  //   right: "0px",
                  //   bottom: "0px",
                  //   top: "0px",
                  //   left: "0px",
                  // }}
                >
                  <DialogTitle>Upload file.</DialogTitle>
                  <DialogContent>
                    {/* <DialogContentText>
                        To subscribe to this website, please enter your email
                        address here. We will send updates occasionally.
                      </DialogContentText> */}
                    <UploadMediaModal
                      handleMultiFileUpload={handleMultiFileUpload}
                      setSelectedVideoFileType={setSelectedVideoFileType}
                      setSelectedAudioType={setSelectedAudioType}
                      state={state}
                      handleClose={handleClose}
                      previewVideoNeeded={previewVideoNeeded}
                      previewImgNeeded={previewImgNeeded}
                      preview3DNeeded={preview3DNeeded}
                      disableSupportingDocs={disableSupportingDocs}
                      isSuplimentaryFile1Video={isSuplimentaryFile1Video}
                      isSuplimentaryFile2Video={isSuplimentaryFile2Video}
                      isSuplimentaryFile3Video={isSuplimentaryFile3Video}
                      isSuplimentaryFile4Video={isSuplimentaryFile4Video}
                      handleRemoveFile={handleRemoveFile}
                      handleDeleteAll={handleDeleteAll}
                      isSuplimentaryFile13d={isSuplimentaryFile13d}
                      isSuplimentaryFile23d={isSuplimentaryFile23d}
                      isSuplimentaryFile33d={isSuplimentaryFile33d}
                      isSuplimentaryFile43d={isSuplimentaryFile43d}
                      isMainFile3D={isMainFile3D}
                    />
                    {/* <UploadMediaModal
                      handleImageUpload={handleImageUpload}
                      setSelectedVideoFileType={setSelectedVideoFileType}
                      setSelectedAudioType={setSelectedAudioType}
                      state={state}
                    /> */}
                  </DialogContent>
                  {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                  </DialogActions> */}
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
