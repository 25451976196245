import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { SAVE_TOKEN, SAVE_CRYPTO_VALUE } from "../../redux/actionTypes";
import { axiosInstance } from "../../utility/api";
// import man from "../../assets/images/dashboard/profile-pic.jpg";
import {
	// FileText,
	LogIn,
	// Mail,
	User,
	Lock,
	// MessageSquare,
	// Bell,
	// Minimize,
	// Search,
	// ShoppingCart,
	// Minus,
	// Plus,
	// X,
} from "react-feather";
// import Bookmark from "../../layout/bookmark";
import {
	setTranslations,
	setDefaultLanguage,
	setLanguageCookie,
	// setLanguage,
	translate,
} from "react-switch-lang";

import {
	// English,
	// Deutsch,
	// Español,
	// Français,
	// Português,
	// 简体中文,
	// Notification,
	// DeliveryProcessing,
	// OrderComplete,
	// TicketsGenerated,
	// DeliveryComplete,
	// CheckAllNotification,
	// ViewAll,
	// MessageBox,
	// EricaHughes,
	// KoriThomas,
	Admin,
	// Account,
	// Inbox,
	// Taskboard,
	LogOut,
	ChangePassword,
	Profile,
	// AinChavez,
	// CheckOut,
	// ShopingBag,
	// OrderTotal,
	// GoToShopingBag,
} from "../../constant";

import Auth from "../../utility/auth";

import en from "../../assets/i18n/en.json";
import es from "../../assets/i18n/es.json";
import pt from "../../assets/i18n/pt.json";
import fr from "../../assets/i18n/fr.json";
import du from "../../assets/i18n/du.json";
import cn from "../../assets/i18n/cn.json";
import ae from "../../assets/i18n/ae.json";
// import { InputGroup, InputGroupAddon, Button } from "reactstrap";
import { Link } from "react-router-dom";

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage("en");
setLanguageCookie();

const Rightbar = (props) => {
	// const [searchresponsive, setSearchresponsive] = useState(false);
	// const [langdropdown, setLangdropdown] = useState(false);
	const [moonlight, setMoonlight] = useState(false);
	// const [selected, setSelected] = useState("en");
	// const [cartDropdown, setCartDropDown] = useState(false);
	// const [notificationDropDown, setNotificationDropDown] = useState(false);
	// const [chatDropDown, setChatDropDown] = useState(false);
	const [onClickAdmin, setOnClickAdmin] = useState(false);
	const dispatch = useDispatch();

	const config = {
		headers: {
			Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
		},
	};

	const adminAccountDetails = useSelector((state) => state.adminReducer);
	// console.log({ adminAccountDetails });

	// const handleSetLanguage = (key) => {
	//   setLanguage(key);
	//   setSelected(key);
	// };

	useEffect(() => {
		if (localStorage.getItem("layout_version") === "dark-only") {
			setMoonlight(true);
		}
		getCryptoExchangeRate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getCryptoExchangeRate = async () => {
		await axiosInstance
			.get(`/v1/admin/exchange/rate`, config)
			.then((res) => {
				// console.log("Matic value : ", res?.data?.result?.exchangeRate);
				let payload = {
					exchangeRate: res?.data?.result?.exchangeRate,
				};
				dispatch({
					type: SAVE_CRYPTO_VALUE,
					payload: payload,
				});
				// setEthValue(state?.regularPrice * res?.data?.result?.exchangeRate);
			})
			.catch((error) => {
				console.log("Error getting ETH Value", error);
			});
	};

	//full screen function
	// function goFull() {
	//   if (
	//     (document.fullScreenElement && document.fullScreenElement !== null) ||
	//     (!document.mozFullScreen && !document.webkitIsFullScreen)
	//   ) {
	//     if (document.documentElement.requestFullScreen) {
	//       document.documentElement.requestFullScreen();
	//     } else if (document.documentElement.mozRequestFullScreen) {
	//       document.documentElement.mozRequestFullScreen();
	//     } else if (document.documentElement.webkitRequestFullScreen) {
	//       document.documentElement.webkitRequestFullScreen(
	//         Element.ALLOW_KEYBOARD_INPUT
	//       );
	//     }
	//   } else {
	//     if (document.cancelFullScreen) {
	//       document.cancelFullScreen();
	//     } else if (document.mozCancelFullScreen) {
	//       document.mozCancelFullScreen();
	//     } else if (document.webkitCancelFullScreen) {
	//       document.webkitCancelFullScreen();
	//     }
	//   }
	// }

	// const SeacrhResposive = (searchresponsive) => {
	//   if (searchresponsive) {
	//     setSearchresponsive(!searchresponsive);
	//     document.querySelector(".search-full").classList.add("open");
	//     document.querySelector(".more_lang").classList.remove("active");
	//   } else {
	//     setSearchresponsive(!searchresponsive);
	//     document.querySelector(".search-full").classList.remove("open");
	//   }
	// };

	// const LanguageSelection = (language) => {
	//   if (language) {
	//     setLangdropdown(!language);
	//   } else {
	//     setLangdropdown(!language);
	//   }
	// };

	// const MoonlightToggle = (light) => {
	//   if (light) {
	//     setMoonlight(!light);
	//     document.body.className = "light";
	//     localStorage.setItem("layout_version", "light");
	//   } else {
	//     setMoonlight(!light);
	//     document.body.className = "dark-only";
	//     localStorage.setItem("layout_version", "dark-only");
	//   }
	// };

	const handleClick = (e) => {
		dispatch({
			type: SAVE_TOKEN,
			payload: null,
		});
		Auth.logout(() => {
			Swal.fire("Success!", "You've been Logged Out.", "success").then(
				(res) => {
					window.location.reload();
				}
			);
		});
		localStorage.removeItem("token");
	};

	return (
		<Fragment>
			<div className="nav-right col-8 pull-right right-header p-0">
				<ul className="nav-menus">
					{/* <li className="language-nav">
            <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
              <div className="current_lang">
                <div className="lang" onClick={() => LanguageSelection(langdropdown)}>
                  <i className={`flag-icon flag-icon-${selected === "en" ? "us" : selected === "du" ? "de" : selected}`}></i>
                  <span className="lang-txt">{selected}</span>
                </div>
              </div>
              <div className={`more_lang ${langdropdown ? 'active' : ''}`}>
                <div className="lang" onClick={() => handleSetLanguage('en')}><i className="flag-icon flag-icon-us"></i><span className="lang-txt">{English}<span> {"(US)"}</span></span></div>
                <div className="lang" onClick={() => handleSetLanguage('du')}><i className="flag-icon flag-icon-de"></i><span className="lang-txt">{Deutsch}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('es')}><i className="flag-icon flag-icon-es"></i><span className="lang-txt">{Español}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('fr')}><i className="flag-icon flag-icon-fr"></i><span className="lang-txt">{Français}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('pt')}><i className="flag-icon flag-icon-pt"></i><span className="lang-txt">{Português}<span> {"(BR)"}</span></span></div>
                <div className="lang" onClick={() => handleSetLanguage('cn')}><i className="flag-icon flag-icon-cn"></i><span className="lang-txt">{简体中文}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('ae')}><i className="flag-icon flag-icon-ae"></i><span className="lang-txt">{"لعربية"}<span> {"(ae)"}</span></span></div>
              </div>
            </div>
          </li>
          <li><span className="header-search"><Search onClick={() => SeacrhResposive(searchresponsive)} /></span></li>
          <li className="onhover-dropdown">
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell /><span className="badge badge-pill badge-secondary">2</span></div>
            <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
              <li>
                <Bell />
                <h6 className="f-18 mb-0">{Notification}</h6>
              </li>
              <li>
                <p><i className="fa fa-circle-o mr-3 font-primary"> </i>{DeliveryProcessing} <span className="pull-right">{"10 min."}</span></p>
              </li>
              <li>
                <p><i className="fa fa-circle-o mr-3 font-success"></i>{OrderComplete}<span className="pull-right">{"1 hr"}</span></p>
              </li>
              <li>
                <p><i className="fa fa-circle-o mr-3 font-info"></i>{TicketsGenerated}<span className="pull-right">{"3 hr"}</span></p>
              </li>
              <li>
                <p><i className="fa fa-circle-o mr-3 font-danger"></i>{DeliveryComplete}<span className="pull-right">{"6 hr"}</span></p>
              </li>
              <li><button className="btn btn-primary" >{CheckAllNotification}</button>
              </li>
            </ul>
          </li>
          <Bookmark/>
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>
          <li className="cart-nav onhover-dropdown">
            <div className="cart-box" onClick={() => setCartDropDown(!cartDropdown)}><ShoppingCart/><span className="badge badge-pill badge-primary">{"2"}</span></div>
            <ul className={`cart-dropdown onhover-show-div ${cartDropdown ? "active" : ""}`}>
              <li>
                <h6 className="mb-0 f-20">{ShopingBag}</h6><ShoppingCart/>
              </li>
              <li className="mt-0">
                <div className="media" ><img className="img-fluid rounded-circle mr-3 img-60" src={require("../../assets//images/ecommerce/01.jpg")} alt=""/>
                  <div className="media-body"><span>{"V-Neck Shawl Collar Woman's Solid T-Shirt"}</span>
                    <p>{"Yellow(#fcb102)"}</p>
                    <div className="qty-box">
                      <InputGroup>
                          <InputGroupAddon addonType="prepend">
                              <button className="btn quantity-left-minus" type="button" data-type="minus" data-field=""><Minus/></button>
                          </InputGroupAddon>
                            <input className="form-control input-number" type="text" name="quantity" defaultValue="1"/>
                          <InputGroupAddon addonType="prepend">
                              <button className="btn quantity-right-plus" type="button" data-type="plus" data-field=""><Plus/></button>
                          </InputGroupAddon>
                      </InputGroup>
                    </div>
                    <h6 className="text-right text-muted">{"$299.00"}</h6>
                  </div>
                  <div className="close-circle"><a href="#javascript"><X/></a></div>
                </div>
              </li>
              <li className="mt-0">
                <div className="media" ><img className="img-fluid rounded-circle mr-3 img-60" src={require("../../assets//images/ecommerce/03.jpg")} alt=""/>
                  <div className="media-body"><span>{"V-Neck Shawl Collar Woman's Solid T-Shirt"}</span>
                    <p>{"Yellow(#fcb102)"}</p>
                    <div className="qty-box">
                      <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <button className="btn quantity-left-minus" type="button" data-type="minus" data-field=""><Minus/></button>
                          </InputGroupAddon>
                          <input className="form-control input-number" type="text" name="quantity" defaultValue="1"/>
                          <InputGroupAddon addonType="prepend">
                            <button className="btn quantity-right-plus" type="button" data-type="plus" data-field=""><Plus/></button>
                          </InputGroupAddon>
                      </InputGroup>
                    </div>
                    <h6 className="text-right text-muted">{"$299.00"}</h6>
                  </div>
                  <div className="close-circle"><a href="#javascript"><X/></a></div>
                </div>
              </li>
              <li>
                <div className="total">
                  <h6 className="mb-2 mt-0 text-muted">{OrderTotal} : <span className="f-right f-20">{"$598.00"}</span></h6>
                </div>
              </li>
              <li>
                <Button color="primary" className="btn btn-block view-cart">{GoToShopingBag}</Button>
                <Button color="secondary" className="btn-block view-cart mt-2">{CheckOut}</Button>
              </li>
            </ul>
          </li>
          <li className="onhover-dropdown" onClick={() => setChatDropDown(!chatDropDown)}><MessageSquare />
            <ul className={`chat-dropdown onhover-show-div ${chatDropDown ? "active" : ""}`}>
              <li>
                <MessageSquare />
                <h6 className="f-18 mb-0">{MessageBox}</h6>
              </li>
              <li>
                <div className="media"><img className="img-fluid rounded-circle mr-3" src={require("../../assets/images/user/1.jpg")} alt="" />
                  <div className="status-circle online"></div>
                  <div className="media-body"><span>{EricaHughes}</span>
                    <p>{"Lorem Ipsum is simply dummy..."}</p>
                  </div>
                  <p className="f-12 font-success">{"58 mins ago"}</p>
                </div>
              </li>
              <li>
                <div className="media"><img className="img-fluid rounded-circle mr-3" src={require("../../assets/images/user/2.jpg")} alt="" />
                  <div className="status-circle online"></div>
                  <div className="media-body"><span>{KoriThomas}</span>
                    <p>{"Lorem Ipsum is simply dummy..."}</p>
                  </div>
                  <p className="f-12 font-success">{"1 hr ago"}</p>
                </div>
              </li>
              <li>
                <div className="media"><img className="img-fluid rounded-circle mr-3" src={require("../../assets/images/user/4.jpg")} alt="" />
                  <div className="status-circle offline"></div>
                  <div className="media-body"><span>{AinChavez}</span>
                    <p>{"Lorem Ipsum is simply dummy..."}</p>
                  </div>
                  <p className="f-12 font-danger">{"32 mins ago"}</p>
                </div>
              </li>
              <li className="text-center"> <button className="btn btn-primary">{ViewAll}     </button></li>
            </ul>
          </li>
          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Minimize /></a></li> */}
					<li
						className={`profile-nav onhover-dropdown p-0 ${
							onClickAdmin ? "doHover" : ""
						}`}
					>
						<div
							className="media profile-media "
							onClick={() => setOnClickAdmin((data) => !data)}
						>
							<img
								className="b-r-10"
								src={
									adminAccountDetails?.profilePicture
										? adminAccountDetails?.profilePicture
										: adminAccountDetails?.userId?.thumbnailImageUrl
								}
								alt=""
							/>
							<div className="media-body">
								<span>{adminAccountDetails?.firstName}</span>
								<p className="mb-0 font-roboto">
									{Admin} <i className="middle fa fa-angle-down"></i>
								</p>
							</div>
						</div>
						<ul className="profile-dropdown onhover-show-div">
							{/* <li><User /><span>{Account} </span></li>
              <li><Mail /><span>{Inbox}</span></li>
              <li><FileText /><span>{Taskboard}</span></li> */}
							<li>
								<Link to="/user/editprofile/:layout">
									<User />
									<span>{Profile}</span>
								</Link>
							</li>
							<li>
								<Link to="/user/changepassword/:layout`">
									<Lock />
									<span>{ChangePassword}</span>
								</Link>
							</li>
							<li>
								<Link to="/" onClick={handleClick}>
									<LogIn />
									<span>{LogOut}</span>
								</Link>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</Fragment>
	);
};
export default translate(Rightbar);
