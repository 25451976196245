import React, { Fragment } from 'react';
import Breadcrumb from '../../layout/breadcrumb';
import { Container, Row, Col } from 'reactstrap';


const Welcome = (props) => {
    return (
        <Fragment>
                <Breadcrumb parent="Dashboard" title="Welcome to lii." />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <p style={{ fontSize: 17, fontWeight: "500" }}>
                                <br/>
                                Browse using avaliable options in the Sidebar Navigation.
                            </p>

                        </Col>
                    </Row>
                </Container>
        </Fragment>
    );
}

export default Welcome;